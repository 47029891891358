import React from 'react';
import Footer from './Footer';
import Header1 from './Header1';
import SocialMediaIcons from './SocialMediaIcons';

class AboutUsPage extends React.Component {
  render() {
    return (
      <div>
        {/* Navbar */}
        <Header1 />
        <SocialMediaIcons />
        {/* About Us Section */}
        <section id="about" className="py-5">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <h2 className="display-4">About Jinendra Online</h2>
                <p className="lead">Jinendra Online is your one-stop destination for all your shopping needs. We offer a wide range of products from electronics to fashion, home goods to toys, and much more. Our mission is to provide high-quality products at affordable prices, delivered right to your doorstep.</p>
                <p>We strive to make your shopping experience seamless and enjoyable. With a user-friendly interface, secure payment options, and efficient customer service, we ensure that you have a hassle-free experience every time you shop with us.</p>
              </div>
              <div className="col-lg-6">
                <img src="/images/jinendra.jpg" alt="About Us" className="img-fluid rounded-circle" />
              </div>
            </div>
          </div>
        </section>
        {/* Mission Section */}
        <section id="mission" className="bg-light py-5">
          <div className="container">
            <h2 className="text-center mb-5">Our Mission</h2>
            <div className="row">
              <div className="col-md-4">
                <div className="card">
                  <img src="/images/product-quality.png" className="card-img-top" alt="Mission 1" height={250} />
                  <div className="card-body">
                    <h5 className="card-title">Quality Products</h5>
                    <p className="card-text">We are committed to offering only the best quality products to our customers.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card">
                  <img src="/images/customer.png" className="card-img-top" alt="Mission 2" height={250} />
                  <div className="card-body">
                    <h5 className="card-title">Customer Satisfaction</h5>
                    <p className="card-text">Ensuring our customers are satisfied with their purchases is our top priority.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card">
                  <img src="/images/delivery.jpg" className="card-img-top" alt="Mission 3" height={250} />
                  <div className="card-body">
                    <h5 className="card-title">Fast Delivery</h5>
                    <p className="card-text">We aim to deliver your orders quickly and efficiently.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Team Section */}
        <section id="team" className="py-5">
          <div className="container">
            <h2 className="text-center mb-5">Meet Our Team</h2>
            <div className="row">
              <div className="col-md-4">
                <div className="card">
                  <img src="https://via.placeholder.com/350x200" className="card-img-top" alt="Team Member 1" />
                  <div className="card-body">
                    <h5 className="card-title">John Doe</h5>
                    <p className="card-text">Founder & CEO</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card">
                  <img src="https://via.placeholder.com/350x200" className="card-img-top" alt="Team Member 2" />
                  <div className="card-body">
                    <h5 className="card-title">Jane Smith</h5>
                    <p className="card-text">Marketing Manager</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card">
                  <img src="https://via.placeholder.com/350x200" className="card-img-top" alt="Team Member 3" />
                  <div className="card-body">
                    <h5 className="card-title">Michael Johnson</h5>
                    <p className="card-text">Customer Support Specialist</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}

export default AboutUsPage;