import React, { useState } from 'react'


const StarRating = ({ value, onClick }) => {
    const stars = [1, 2, 3, 4, 5];
    const [hoveredStar, setHoveredStar] = useState(null);
  
    return (
      <div className="d-inline-flex align-items-center">
        {stars.map(star => (
          <span
            key={star}
            className="star-icon"
            onClick={() => onClick(star)}
            onMouseEnter={() => setHoveredStar(star)}
            onMouseLeave={() => setHoveredStar(null)}
          >
            {star <= (hoveredStar || value) ? (
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                <path fill={star <= value ? "#FF5A00" : "#FF5A00"} d="M12 2l2.76 8.225h8.509l-6.885 5.043 2.77 8.732-7.265-5.319-7.266 5.319 2.771-8.732-6.885-5.043h8.51l2.76-8.225z"/>
              </svg>
            ) : (
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                <path fill="#000000" d="M12 2l2.76 8.225h8.509l-6.885 5.043 2.77 8.732-7.265-5.319-7.266 5.319 2.771-8.732-6.885-5.043h8.51l2.76-8.225z" opacity="0.3"/>
              </svg>
            )}
          </span>
        ))}
      </div>
    );
  };


function Review() {
    const [formData, setFormData] = useState({
        price: 0,
        quality: 0,
        value: 0,
        name: '',
        summary: '',
        review: ''
      });
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
          ...prevState,
          [name]: value
        }));
      };

      const handleRatingChange = (field, value) => {
        setFormData(prevState => ({
          ...prevState,
          [field]: value
        }));
      };
    
    
      const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission here
        // console.log(formData);
      };
  return (
    <div>
        <div className='row p-3' >
            <div className='col-lg-3 p-3'>
                <div>
                    <span className='d-flex ms-3'><span className='fs-2'>0</span>
                    <div className='d-flex mt-2 ms-2'>
                        <i class="fa-regular fa-star"></i>
                        <i class="fa-regular fa-star"></i>
                        <i class="fa-regular fa-star"></i>
                        <i class="fa-regular fa-star"></i>
                        <i class="fa-regular fa-star"></i>
                    </div>
                    <span className='mt-1 ms-2'> Reviews</span>
                    </span>
                </div>
                <ul className="list-unstyled">
                    <li className="d-flex align-items-center">
                        <span className='ml-2 me-2'>5 star</span>
                        <div className="progress flex-grow-1">
                        <div className="progress-bar bg-success" role="progressbar" style={{ width: '100%' }} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                        
                    </li>
                    <li className="d-flex align-items-center">
                        <span className='ml-2 me-2'>4 star</span>
                        <div className="progress flex-grow-1">
                        <div className="progress-bar bg-success" role="progressbar" style={{ width: '80%' }} aria-valuenow="80" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                        
                    </li>
                    <li className="d-flex align-items-center">
                        <span className='ml-2 me-2'>3 star</span>
                        <div className="progress flex-grow-1">
                        <div className="progress-bar bg-success" role="progressbar" style={{ width: '60%' }} aria-valuenow="60" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                       
                    </li>
                    <li className="d-flex align-items-center">
                        <span className='ml-2 me-2'>2 star</span>
                        <div className="progress flex-grow-1">
                        <div className="progress-bar bg-success" role="progressbar" style={{ width: '40%' }} aria-valuenow="40" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                        
                    </li>
                    <li className="d-flex align-items-center">
                        <span className='ml-2 me-2'>1 star</span>
                        <div className="progress flex-grow-1">
                        <div className="progress-bar bg-success" role="progressbar" style={{ width: '20%' }} aria-valuenow="20" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                        
                    </li>
                </ul>


            </div>
            <div className='col-lg-9 p-3 ' style={{backgroundColor:"#F8F5EA"}}>
                <form onSubmit={handleSubmit}>
                    <p className='fs-5'>YOU'RE REVIEWING:</p>
                    <h4>Main Title</h4>
                    <hr/>
                    <div className='mb-5'>
                     <p className='fw-bold'>Your Rating<span  style={{color:"red"}}>*</span></p>   
                        <div className="form-group d-flex">
                            <label style={{marginRight:"45px"}}>Price</label>
                            <StarRating  value={formData.price} onClick={value => handleRatingChange('price', value)}  required/>
                        </div>
                        <div className="form-group d-flex">
                            <label style={{marginRight:"30px"}}>Quality</label>
                            <StarRating value={formData.quality} onClick={value => handleRatingChange('quality', value)} required/>
                        </div>
                        <div className="form-group d-flex">
                            <label style={{marginRight:"42px"}} >Value</label>
                            <StarRating value={formData.value} onClick={value => handleRatingChange('value', value)} required/>
                        </div>

                    </div>
                    
                    <div className="form-group mb-4">
                        <label htmlFor="name">Name<span  style={{color:"red"}}>*</span></label>
                        <input type="text" className="form-control" id="name" name="name" value={formData.name} onChange={handleChange} required />
                    </div>
                    <div className="form-group mb-4">
                        <label htmlFor="summary">Summary<span  style={{color:"red"}}>*</span></label>
                        <input type="text" className="form-control" id="summary" name="summary" value={formData.summary} onChange={handleChange} required/>
                    </div>
                    <div className="form-group mb-5">
                        <label htmlFor="review">Review<span  style={{color:"red"}}>*</span></label>
                        <textarea className="form-control" id="review" name="review" rows="3" value={formData.review} onChange={handleChange} required></textarea>
                    </div>
                    <button type="submit" className="btn rounded-5 text-white w-md-25 fw-bold" style={{backgroundColor:"#FF5A00", border:"1px solid #FF5A00"}}>SUBMIT REVIEW</button>
                </form>
            </div>

        </div>
      
    </div>
  )
}

export default Review;