import React, { useEffect, useState } from 'react';
import Header1 from '../Header1';
import Footer from '../Footer';

function MyAccount() {
  const [userData, setUserData] = useState(null);
  const [filteredUserData, setFilteredUserData] = useState(null);
  const [loggedInUserId, setLoggedInUserId] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("https://jinendra.online/api/getuserRecords");

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setUserData(data);

      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const authString = localStorage.getItem("user");
    const auth = JSON.parse(authString);
    if (auth && auth.userId) {
      setLoggedInUserId(auth.userId);
    }
  }, []);

  useEffect(() => {
    if (userData && userData.data && loggedInUserId) {
      const filteredData = userData.data.filter(record => record.userId === loggedInUserId);
      setFilteredUserData(filteredData);
    }
  }, [userData, loggedInUserId]);

  return (
    <>
      <Header1 />
      <div className='py-5' style={{ backgroundColor: "#F5F3EA" }}>
        <div className=" container ">
          <div className=" border bg-white  flex-1" style={{ padding: "20px", flex: "1" }}>
            <h3>Account Information</h3>
            <hr />
            {filteredUserData && (
              <div>
                <h4>Contact Information</h4>
                <div style={{ listStyle: "none" }}>
                  {filteredUserData.map((record, index) => (
                    <span key={index} style={{ textDecoration: "none" }}>
                      Name: {record.firstname}&nbsp;{record.lastname}<br />
                      Email: {record.email}<br />
                    </span>
                  ))}
                </div>
              </div>
            )}

          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default MyAccount;
