import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ProductDetailPopup from './ProductDetailPopup';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from 'axios'
import { productAddedToCart } from './ProductDetail';
import { handleAddToCompare } from './ProductDetail'
import { Tooltip as ReactTooltip } from 'react-tooltip'

function PdHomes() {
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [displayedProducts, setDisplayedProducts] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const fetchProducts = async () => {
    try {
      setLoading(true);
      const response = await fetch('https://jinendra.online/api/products_Productsdetails');
      const data = await response.json();
      setProducts(data.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching products:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  useEffect(() => {
    setDisplayedProducts(products.slice(0, 10)); // Display only the first 10 products initially
  }, [products]);

  const handleShowMoreClick = () => {
    setDisplayedProducts(products); // Display all products when "Show More" button is clicked
    setShowAll(true); // Update state to indicate that all products are being displayed
  };

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 2,
    initialSlide: 0,
    prevArrow: <></>,
    nextArrow: <></>,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 0
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 0
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 0
        }
      }
    ]
  };

  var settings1 = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 2,
    prevArrow: <></>,
    nextArrow: <></>,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 0
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          initialSlide: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1
        }
      }
    ]
  };

  // Filter products based on section
  const filteredProducts = section => products.filter(product => product.section === section);
  const [count, setCount] = useState(1); // Set initial count to 1
  const increment = () => {
    setCount(prevCount => prevCount + 1);
  };

  const decrement = () => {
    // Check if count is greater than 1 before decrementing
    if (count > 1) {
      setCount(prevCount => prevCount - 1);
    }
  };
  useEffect(() => {
    localStorage.setItem('count', count.toString());
  }, [count]);

  const addToWishlist = async (product) => {
    // Check if the user is logged in
    const auth = localStorage.getItem('user');
    const userId = auth ? JSON.parse(auth).userId : null;

    if (!userId) {
      alert('Please login to add products to wishlist');
      return;
    }

    try {
      // Make the POST request to add the product to the wishlist
      const response = await axios.post('https://jinendra.online/api/addwishlist', {
        ...product,
        userId: userId // Include the user ID in the product data
      });

      if (response.status === 201) {

        // console.log('Product added to wishlist successfully');
      } else {
        console.error('Failed to add product to wishlist:', response.data.message);
        // Handle the error
      }

      alert("Product add to wishlist")
    } catch (error) {
      console.error('Error adding product to wishlist:', error);
      // Handle network errors or other exceptions
    }
  };
  const [product1, setProduct1] = useState(null);

  useEffect(() => {
    const fetchProductsDetails = async () => {
      try {
        const response = await axios.get('https://jinendra.online/api/products_Productsdetails');
        console.log('Fetched product1 data:', response.data.data1);
        setProduct1(response.data.data1);
      } catch (error) {
        console.error('Error fetching product1:', error);
      }
    };

    fetchProductsDetails();
  }, []);

  return (
    <div style={{ backgroundColor: "#F5F3EA" }}>
      <div className="container">
        <p className="text-start mb-4 fs-4 fw-bold">iPhone @ Best Deal + Cashback</p>
        <div className="row row-cols-2 row-cols-md-3 row-cols-lg-5">
          {filteredProducts("iPhone @ Best Deal + Cashback").map(product => (
            <div key={product._id} className="col mb-4 p-lg-2 p-1">
              <div className="card product-card p-2">
                <img src={`https://jinendra.online/${product.prodimg1}`} className="card-img-top  img-fluid" alt={product.productName} style={{ maxHeight: "200px" }} />
                <div className="overlay"></div>
                <div className="card-body">
                  <div className="product-details text-center">
                    <h5 className="card-title">{product.productName}</h5>
                    <div className="price-info d-flex flex-column flex-md-row justify-content-between pt-3">
                      <p className="card-text old-price mb-3 mb-md-0">₹{product.orgPrice}</p>
                      <p className="card-text text-dark">₹{product.discountedPrice}</p>
                    </div>
                    <div className='d-none d-lg-block'>
                      <div className="add-to-cart-btn ">
                        {/* Your add to cart buttons */}
                        <div className='px-4'>

                          <div className='row'>

                            <div className='col-8 d-flex justify-content-center align-items-center p-0 m-0'>
                              <Link to={`/cart`} className="d-block text-center">
                                <button className="btn btn-primary px-3 m-0 border-0  pulse" type="button" style={{ backgroundColor: '#1D1D1F', color: 'white', width: '100%', fontSize: "12px", boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }} onClick={() => productAddedToCart(product, count)}><span className='co'>ADD TO CART</span></button>
                              </Link>
                            </div>

                            <div className='col-2 d-flex px-1  ' >
                              <div className='rounded-circle btn btn-primary  buy-btns' data-tooltip-id="my-tooltip-2" style={{ cursor: 'pointer', backgroundColor: 'black', color: 'white', width: '35px', height: '35px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => addToWishlist(product, product1)}
                              >
                                <i class="fa-solid fa-heart co"  ></i>
                              </div>
                            </div>

                            <ReactTooltip
                              id="my-tooltip-2"
                              place="top"
                              content="Wishlist"
                            />
                            {/* onClick={() => handleAddToCompare(product)} */}
                            <div className='col-2 d-flex '>
                              <div className='rounded-circle btn btn-primary buy-btns' data-tooltip-id="my-tooltip-3" style={{ cursor: 'pointer', backgroundColor: 'black', color: 'white', width: '35px', height: '35px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <i className="fa-solid fa-arrow-right-arrow-left co"></i>
                              </div>
                            </div>

                            <ReactTooltip
                              id="my-tooltip-3"
                              place="bottom"
                              content="Compair"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* small devices */}
                    <div className="col-12  d-block d-lg-none mt-2">
                      <div className="row justify-content-center">
                        <div className="col-4 d-flex justify-content-center">
                          <Link to={`/cart`} className="d-block text-center">
                            <button className="btn btn-primary buy-btns" type="button" style={{ backgroundColor: 'black', color: 'white', width: '100%' }} onClick={() => productAddedToCart(product, count)}>
                              <i className="fa-solid fa-bag-shopping co"></i>
                            </button>
                          </Link>
                        </div>
                        <div className="col-4 d-flex justify-content-center">
                          <div className="rounded-circle btn btn-primary buy-btns" style={{ cursor: 'pointer', backgroundColor: 'black', color: 'white', width: '35px', height: '35px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => addToWishlist(product, product1)}>
                            <i className="fa-solid fa-heart co"></i>
                          </div>
                        </div>
                        {/* onClick={() => handleAddToCompare(product)} */}
                        <div className="col-4 d-flex justify-content-center">
                          <div className="rounded-circle btn btn-primary buy-btns" style={{ cursor: 'pointer', backgroundColor: 'black', color: 'white', width: '35px', height: '35px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                            <i className="fa-solid fa-arrow-right-arrow-left co"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <ProductDetailPopup productId={product.productId} products={products} deactivateLinkEffect={true} />
                </div>
              </div>
            </div>
          ))}
        </div>
        {!showAll && (
          <div className="text-center">
            <button className="btn btn-white mb-3 rounded-5 w-25 fw-bold" style={{ border: "1px solid #1D1D1F", color: "#1D1D1F" }} onClick={handleShowMoreClick} disabled={loading}>
              {loading ? 'Loading...' : 'LOAD MORE'}
            </button>
          </div>
        )}

        {/* //Jinendra Prime | Elevate your Experience */}
        <h1 className="text-start mt-4 fs-4 fw-bold">jinendra Prime | Elevate your Experience</h1>

        <div className="row pt-3  pb-3">

          <div className="col-lg-6 bg-white">

            <div className="d-flex flex-column flex-md-row">
              <Link to="/productdetails">
                <img src='./images/tv.jpg' className="img-fluid" alt="TV" />
              </Link>
              <div className='w-100 w-md-75 p-3'>
                <Link to='/productdetails' style={{ textDecoration: "none", color: "black" }}>
                  <h6 className="mb-0">Xiaomi Smart LED TV 5A (32inch, 80cm)<br /> HD Ready Android TV (2022 Model)</h6>
                </Link>
                <p className='fs-5 fw-bold '>
                  <del className='me-2' style={{ color: "#C3C3C3" }} >₹13,999.00</del>₹24,999.00
                </p>
                <p style={{ fontSize: "14px", color: "#C3C3C3" }} className="mb-md-0">
                  Netflix | Prime Video | Disney+Hotstar | Youtube Android (Google Assistant & Chromecast in-built)
                  HD Ready 1366 x 768 Pixels Resolution 20w - Sound Output
                </p>
                <Link to="/cart">
                  <button className='text-white rounded-2 border-0 p-2 w-lg-25 w-md-50  ' style={{ backgroundColor: "#1D1D1F", marginTop: "5%" }}>
                    <i className="fa-solid fa-cart-shopping text-white me-2 co"></i>ADD TO CART
                  </button>
                </Link>
              </div>
            </div>

            {/* <ProductDetailPopup deactivateLinkEffect={true} /> */}
          </div>

          <div className="col-lg-6 mt-4 mt-sm-1">
            <div className="slider-container" style={{ height: "300px" }}>
              <Slider {...settings}>
                {filteredProducts("jinendra Prime | Elevate your Experience").map(product => (
                  <div key={product._id} className="col mb-5">
                    <div className="card product-card p-1" style={{ width: "95%", maxHeight: "300px" }}>
                      <Link to={`/productdetails/${product.productId}`}>
                        <img src={`https://jinendra.online/${product.prodimg1}`} className="card-img-top image-fluid" alt={product.productName} />
                        <div className="overlay"></div>
                      </Link>

                      <div className="card-body">

                        <div className="product-details text-center">
                          <Link to={`/productdetails/${product.productId}`} style={{ textDecoration: "none", color: "black" }}>
                            <h5 className="card-title">{product.productName}</h5>
                          </Link>
                          <div className="price-info d-flex justify-content-between pt-3" style={{ fontSize: '12px' }}>
                            <p className="card-text old-price m-0">₹{product.orgPrice}</p>
                            <p className="card-text m-0 text-dark">₹{product.discountedPrice}</p>
                          </div>
                          <div className='d-none d-lg-block'>
                            <div className="add-to-cart-btn">
                              {/* Your add to cart buttons */}
                              <div className='row m-0 p-0' >
                                <div className='col-12 col-md-7 d-flex justify-content-center align-items-center bg-black w-100' style={{ border: "3px solid black" }}>
                                  <div className="d-flex justify-content-center align-items-center">
                                    <Link to={`/cart`} className="d-block text-center w-100">
                                      <button className=" border-0 " type="button" style={{ backgroundColor: 'black', color: 'white', width: '85px', height: "40px", fontSize: "11px" }} onClick={() => productAddedToCart(product, count)}><i class="fa-solid fa-cart-shopping pe-1 " style={{ color: "white" }}></i><span className='co'>Add to Cart</span></button>
                                    </Link>
                                  </div>
                                  <div className='col-12 col-md-5 d-flex bg-white w-50'>
                                    <div className='rounded-circle btn btn-primary ' data-tooltip-id="my-tooltip-2" style={{ cursor: 'pointer', backgroundColor: 'white', color: 'black', width: '35px', height: '35px', display: 'flex', justifyContent: 'center', alignItems: 'center', border: "1px solid black" }} onClick={() => addToWishlist(product, product1)}>
                                      <i class="fa-solid fa-heart co"></i>
                                    </div>

                                    <ReactTooltip
                                      id="my-tooltip-2"
                                      place="top"
                                      content="Wishlist"
                                    />
                                    {/* onClick={() => handleAddToCompare(product)} */}
                                    <div className='rounded-circle btn btn-primary' data-tooltip-id="my-tooltip-3" style={{ cursor: 'pointer', backgroundColor: 'white', color: 'black', width: '35px', height: '35px', display: 'flex', justifyContent: 'center', alignItems: 'center', border: "1px solid black" }} >
                                      <i className="fa-solid fa-arrow-right-arrow-left co"></i>
                                    </div>

                                    <ReactTooltip
                                      id="my-tooltip-3"
                                      place="bottom"
                                      content="Compare"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* small devices */}
                          <div className="col-12  d-block d-lg-none mt-2">
                            <div className="row justify-content-center">
                              <div className="col-4 d-flex justify-content-center">
                                <Link to={`/cart`} className="d-block text-center">
                                  <button className="btn btn-primary buy-btns" type="button" style={{ backgroundColor: 'black', color: 'white', width: '100%' }} onClick={() => productAddedToCart(product, count)}>
                                    <i className="fa-solid fa-bag-shopping co"></i>
                                  </button>
                                </Link>
                              </div>
                              <div className="col-4 d-flex justify-content-center">
                                <div className="rounded-circle btn btn-primary buy-btns" style={{ cursor: 'pointer', backgroundColor: 'black', color: 'white', width: '35px', height: '35px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => addToWishlist(product, product1)}>
                                  <i className="fa-solid fa-heart co"></i>
                                </div>
                              </div>
                              {/* onClick={() => handleAddToCompare(product)} */}
                              <div className="col-4 d-flex justify-content-center">
                                <div className="rounded-circle btn btn-primary buy-btns" style={{ cursor: 'pointer', backgroundColor: 'black', color: 'white', width: '35px', height: '35px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                  <i className="fa-solid fa-arrow-right-arrow-left co"></i>
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>

                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
        {/* new arival */}
        <h1 className="text-start mb-4">New Arrivals Smart Phones</h1>
        <div className="row row-cols-2 row-cols-md-3 row-cols-lg-5">
          {filteredProducts("New Arrivals Smart Phones").map(product => (
            <div key={product._id} className="col mb-4 p-lg-2 p-1">
              <div className="card product-card p-2">
                <img src={`https://jinendra.online/${product.prodimg1}`} className="card-img-top image-fluid" alt={product.productName} style={{ maxHeight: "200px" }} />
                <div className="overlay"></div>
                <div className="card-body">
                  <div className="product-details text-center">
                    <h5 className="card-title">{product.productName}</h5>
                    <div className="price-info d-flex flex-column flex-md-row justify-content-between pt-3">
                      <p className="card-text old-price mb-3 mb-md-0">₹{product.orgPrice}</p>
                      <p className="card-text text-dark">₹{product.discountedPrice}</p>
                    </div>

                    <div className='d-none d-lg-block'>
                      <div className="add-to-cart-btn ">
                        {/* Your add to cart buttons */}
                        <div className='px-3'>

                          <div className='row'>

                            <div className='col-8 d-flex justify-content-center align-items-center p-0 m-0'>
                              <Link to={`/cart`} className="d-block text-center">
                                <button className="btn btn-primary px-3 m-0 border-0 link link-four pulse-grow-on-hover" type="button" style={{ backgroundColor: '#1D1D1F', color: 'white', width: '100%', fontSize: "12px", boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }} onClick={() => productAddedToCart(product, count)}> <span className='co'>ADD TO CART</span></button>
                              </Link>
                            </div>

                            <div className='col-2 d-flex px-1  '>
                              <div className='rounded-circle btn btn-primary  buy-btns' data-tooltip-id="my-tooltip-2" style={{ cursor: 'pointer', backgroundColor: 'black', color: 'white', width: '35px', height: '35px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => addToWishlist(product, product1)}>
                                <i class="fa-solid fa-heart co"></i>
                              </div>
                            </div>
                            <ReactTooltip
                              id="my-tooltip-2"
                              place="top"
                              content="Wishlist"
                            />

                            <div className='col-2 d-flex '>
                              <div className='rounded-circle btn btn-primary buy-btns' data-tooltip-id="my-tooltip-3" style={{ cursor: 'pointer', backgroundColor: 'black', color: 'white', width: '35px', height: '35px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => handleAddToCompare(product)}>
                                <i className="fa-solid fa-arrow-right-arrow-left co"></i>
                              </div>
                            </div>
                            <ReactTooltip
                              id="my-tooltip-3"
                              place="top"
                              content="Compair"
                            />

                          </div>
                        </div>
                      </div>
                    </div>
                    {/* small devices */}
                    <div className="col-12 d-block d-lg-none mt-2">
                      <div className="row justify-content-center">
                        <div className="col-4 d-flex justify-content-center">
                          <Link to={`/cart`} className="d-block text-center">
                            <button className="btn btn-primary buy-btns" type="button" style={{ backgroundColor: 'black', color: 'white', width: '100%' }} onClick={() => productAddedToCart(product, count)}>
                              <i className="fa-solid fa-bag-shopping co"></i>
                            </button>
                          </Link>
                        </div>
                        <div className="col-4 d-flex justify-content-center">
                          <div className="rounded-circle btn btn-primary buy-btns" style={{ cursor: 'pointer', backgroundColor: 'black', color: 'white', width: '35px', height: '35px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => addToWishlist(product, product1)}>
                            <i className="fa-solid fa-heart co"></i>
                          </div>
                        </div>
                        <div className="col-4 d-flex justify-content-center">
                          <div className="rounded-circle btn btn-primary buy-btns" style={{ cursor: 'pointer', backgroundColor: 'black', color: 'white', width: '35px', height: '35px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => handleAddToCompare(product)}>
                            <i className="fa-solid fa-arrow-right-arrow-left co"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <ProductDetailPopup productId={product.productId} products={products} deactivateLinkEffect={true} />
                </div>
              </div>
            </div>
          ))}
        </div>
        {!showAll && (
          <div className="text-center">
            <button className="btn btn-white mb-3 rounded-5 w-25 fw-bold" style={{ border: "1px solid #1D1D1F", color: "#1D1D1F" }} onClick={handleShowMoreClick} disabled={loading}>
              {loading ? 'Loading...' : 'LOAD MORE'}
            </button>
          </div>
        )}
        {/* Smart Watch */}
        <h1 className="text-start mb-4">Smart Watch</h1>
        <div className="slider-container" style={{ height: "400px" }}>
          <Slider {...settings1}>
            {filteredProducts("Smart Watch").map(product => (

              <div key={product._id} className="col mb-5 w-100">

                <div className="card product-card p-2" style={{ width: "95%", maxHeight: "350px" }}>
                  <img src={`https://jinendra.online/${product.prodimg1}`} className="card-img-top image-fluid" alt={product.productName} />
                  <div className="overlay"></div>
                  <div className="card-body">
                    <div className="product-details text-center">
                      <h5 className="card-title">{product.productName}</h5>
                      <div className="price-info d-flex justify-content-evenly pt-3" style={{ fontSize: '12px' }}>
                        <p className="card-text old-price m-0">₹{product.orgPrice}</p>
                        <p className="card-text m-0 text-dark">₹{product.discountedPrice}</p>
                      </div>
                      <div className='d-none d-lg-block'>
                        <div className="add-to-cart-btn ">
                          {/* Your add to cart buttons */}
                          <div className='px-3'>

                            <div className='row' style={{ backgroundColor: "" }}>

                              <div className='col-7 d-flex justify-content-center align-items-center p-0 m-0'>
                                <Link to={`/cart`} className="d-block text-center">
                                  <button className="btn btn-primary px-3 m-0 border-0 link link-four pulse-grow-on-hover" type="button" style={{ backgroundColor: '#1D1D1F', color: 'white', width: '100%', fontSize: "11px", boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }} onClick={() => productAddedToCart(product, count)}><span className='co'>ADD TO CART</span></button>
                                </Link>
                              </div>

                              <div className='col-2 d-flex px-1  ' >
                                <div className='rounded-circle btn btn-primary  buy-btns' data-tooltip-id="my-tooltip-2" style={{ cursor: 'pointer', backgroundColor: 'black', color: 'white', width: '35px', height: '35px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => addToWishlist(product, product1)}
                                >
                                  <i class="fa-solid fa-heart co"  ></i>
                                </div>
                              </div>

                              <ReactTooltip
                                id="my-tooltip-2"
                                place="top"
                                content="Wishlist"
                              />

                              <div className='col-2 d-flex '>
                                <div className='rounded-circle btn btn-primary buy-btns' data-tooltip-id="my-tooltip-3" style={{ cursor: 'pointer', backgroundColor: 'black', color: 'white', width: '35px', height: '35px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => handleAddToCompare(product)}>
                                  <i className="fa-solid fa-arrow-right-arrow-left co"></i>
                                </div>
                              </div>

                              <ReactTooltip
                                id="my-tooltip-3"
                                place="bottom"
                                content="Compair"
                              />

                            </div>

                          </div>
                        </div>
                      </div>
                      {/* small devices */}
                      <div className="col-12 mt-2 d-block d-lg-none">
                        <div className="row justify-content-center">
                          <div className="col-4 d-flex justify-content-center">
                            <Link to={`/cart`} className="d-block text-center">
                              <button className="btn btn-primary buy-btns" type="button" style={{ backgroundColor: 'black', color: 'white', width: '100%' }} onClick={() => productAddedToCart(product, count)}>
                                <i className="fa-solid fa-bag-shopping"></i>
                              </button>
                            </Link>
                          </div>
                          <div className="col-4 d-flex justify-content-center">
                            <div className="rounded-circle btn btn-primary buy-btns" style={{ cursor: 'pointer', backgroundColor: 'black', color: 'white', width: '35px', height: '35px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => addToWishlist(product, product1)}>
                              <i className="fa-solid fa-heart"></i>
                            </div>
                          </div>
                          <div className="col-4 d-flex justify-content-center">
                            <div className="rounded-circle btn btn-primary buy-btns" style={{ cursor: 'pointer', backgroundColor: 'black', color: 'white', width: '35px', height: '35px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => handleAddToCompare(product)}>
                              <i className="fa-solid fa-arrow-right-arrow-left"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
}

export default PdHomes;
