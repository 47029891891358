


import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Form from 'react-bootstrap/Form';
import Badge from 'react-bootstrap/Badge';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import debounce from 'lodash.debounce';
import { Tooltip as ReactTooltip } from 'react-tooltip'

function Header1() {
  const [count, setCount] = useState(0);
const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const authString = localStorage.getItem("user");
  const auth = JSON.parse(authString);

  const [selectedCategory, setSelectedCategory] = useState('');
  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
    // console.log('Selected category:', category); // Add a log statement for debugging
  };

  const navigate = useNavigate();
  const handleCategoryClick = (categoryName) => {
    handleCategorySelect(categoryName); // Pass the category name
    navigate(`/productpage/${categoryName}`);
  };

  const handlesubcategoryClick = (categoryName, subcategoryName) => {
    navigate(`/productpage/${categoryName}/${subcategoryName}`);
  };


  const [cartData, setCartData] = useState([])

  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const userData = localStorage.getItem('user');
    if (userData) {
      const { userId } = JSON.parse(userData);
      setUserId(userId);
    }
  }, []);

  useEffect(() => {
    const fetchCartData = async () => {
      try {
        const response = await fetch(`https://jinendra.online/api/showusercart/${userId}`);
        if (response.ok) {
          const cartProductData = await response.json();
          setCartData(cartProductData.data || []);
        } else {
          throw new Error('Failed to fetch cart data');
        }
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    if (userId) {
      fetchCartData();
    }
  }, [userId]);

  const [showLarge, setShowLarge] = useState(false);
  const handleCloseLarge = () => setShowLarge(false);
  const handleShowLarge = () => setShowLarge(true);
  //left side menu
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  //sign up or create Acc side slider
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  //cart side slider
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [subcategoryError, setsubcategoryError] = useState(null);
  const [error, setError] = useState(null);
  //cart
  const [showCart, setShowCart] = useState(false);
  const [items, setItems] = useState([]);
  const [total, setTotal] = useState(0);
  const handleShowCart = () => setShowCart(true);
  const handleCloseCart = () => setShowCart(false);
  const sampleItems = [
    { name: "Item 1", price: 10 },
    { name: "Item 2", price: 20 },
    { name: "Item 3", price: 15 }
  ];

  const handleAddToCart = (item) => {
    setItems([...items, item]);
    setTotal(total + item.price);
  };

  const handlePlaceOrder = () => {
    // Reset the cart
    setItems([]);
    setTotal(0);
    handleCloseCart();
  };

  //search bar
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  const fetchData = async (query) => {
    try {
      const response = await fetch(`https://jinendra.online/api/products_Productsdetails`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const contentType = response.headers.get('content-type');
      if (!contentType || !contentType.includes('application/json')) {
        throw new Error('Unexpected response content type');
      }

      const responseData = await response.json();

      // Log the entire data received from the API
      console.log('Data from API:', responseData);

      // Filter the relevant data
      const filteredResults = responseData.data.filter(item => {
        const keys = Object.keys(item);
        for (const key of keys) {
          const value = item[key];
          if (
            String(key).toLowerCase().includes(query.toLowerCase()) ||
            String(value).toLowerCase().includes(query.toLowerCase())
          ) {
            return true;
          }
        }
        return false;
      });

      setSearchResults(filteredResults);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    if (searchQuery.trim() === '') {
      setSearchResults([]);
      return;
    }

    const debouncedFetchData = debounce(fetchData, 300); // Adjust the delay time as needed

    debouncedFetchData(searchQuery);

    return () => {
      debouncedFetchData.cancel(); // Cancel the debounce on component unmount
    };
  }, [searchQuery]);

  const handleInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleKeyDown = (e) => {
    // Check if backspace is pressed and searchQuery is empty
    if (e.key === 'Backspace' && searchQuery === '') {
      setSearchResults([]);
    }
  };

  const handleSearchIconClick = () => {

    navigate('/catlogPd', { state: { searchResults } });
  };
  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior

    // Check if email or password is empty
     if (!phone || !password) {
      alert('Phone and password are required.');
      return; // Stop execution further if email or password is empty
    }

    try {
      const response = await fetch('https://jinendra.online/api/customer/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
         body: JSON.stringify({ phone, password }),
      });

      const data = await response.json();

      if (response.ok) {
        // Login successful

        const userData = {
          email: data.email,
          userId: data.userId,
          firstname: data.firstname, // Assuming first name is available in data
        };
        localStorage.setItem('user', JSON.stringify(userData));
        // Check if there's a product to add to the cart
        const productToAdd = localStorage.getItem('productToAdd');
        if (productToAdd) {
          // Add the product to the cart
          await productAddedToCart(JSON.parse(productToAdd), count);
          // Remove the product from localStorage
          localStorage.removeItem('productToAdd');
          localStorage.removeItem('count');
        }
        navigate('/');
      } else {
        // Login failed
        alert('Invalid email or password. Please try again.');
        // Optionally, you can also console log the error message from the server
      }
    } catch (error) {
      console.error('Error during login:', error);
    }
  };

  const productAddedToCart = async (product, count) => {
    // Your logic to add product to cart
  };

  useEffect(() => {
    fetch('https://jinendra.online/api/category')
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to fetch categories');
        }
        return response.json();
      })
      .then(data => {
        setCategories(data.data); // Assuming data is an object with a 'data' property containing the array of categories
        setError(null);
      })
      .catch(error => {
        console.error('Error fetching categories:', error);
        setError('Failed to fetch categories. Please try again later.');
      });
  }, []);

  const fetchSubcategories = async (categoryName, categoryId) => {
    try {
      const response = await fetch(`https://jinendra.online/api/subcategory?categoryName=${encodeURIComponent(categoryName)}`);
      if (!response.ok) {
        throw new Error('Failed to fetch subcategories');
      }
      const data = await response.json();
      setSubcategories({ ...subcategories, [categoryId]: data.data });
    } catch (error) {
      console.error('Error fetching subcategories:', error);
    }
  };

  const [subtotal, setSubtotal] = useState(0); // State to hold subtotal
  useEffect(() => {
    // Calculate subtotal whenever cart data changes
    calculateSubtotal();
  }, [cartData]);

  const calculateSubtotal = () => {
    let total = 0;
    cartData.forEach((item) => {
      total += item.discountedPrice * item.quantity;
    });
    setSubtotal(total);
  };

  // delet api
  const deleteCartItem = async (cartId) => {
    try {
      const response = await fetch(`https://jinendra.online/api/deletecart/${cartId}`, {
        method: 'DELETE', // Use DELETE method for deleting data
      });
      if (!response.ok) {
        throw new Error(`Failed to delete cart item. Status: ${response.status} ${response.statusText}`);
      }
      const data = await response.json();
      setCartData(prevCartData => prevCartData.filter(item => item.cId !== cartId));
    } catch (error) {
    }
  }

  const totalQuantity = cartData.reduce((total, cartItem) => total + cartItem.quantity, 0);

  // toggle button for samll nave menu side slider
  const [view1Visible, setView1Visible] = useState(true);

  const toggleViews = () => {
    setView1Visible(!view1Visible);
  };

  const userGetLogout = () => {
    const confirmLogout = window.confirm("Are you sure?");
    if (confirmLogout) {
      localStorage.clear();
      handleClose()
      handleClose2()
      window.location.href = '/';
    }
  }

  const handleUnauthorizedAccess = () => {
    alert("Please log in to access this page.");
  };

  const handleWishlistClick = () => {
    if (!auth) {
      alert('Please login to view your wishlist.');
    }
  };

  const [selectedItem, setSelectedItem] = useState('All Catogery');

  const handleItemClick = (item) => {
    setSelectedItem(item);
  };

  return (
    <>
      {/* nav bar for large and medium screen */}
      <div className='d-none d-lg-block m-0' style={{ backgroundColor: "#1D1D1F", paddingLeft: "60px" }}>
        <div className='row'>
          <div className='col-lg-1 col-md-6  text-center p-0'>
            <div>
              <Button variant="" className='mt-3' style={{ width: "40px", height: "40px", border: '1px solid white' }} onClick={handleShowLarge}>
                <i className="fa-solid fa-bars fs-5" style={{ color: "white" }}></i>
              </Button>

              <Offcanvas show={showLarge} onHide={handleCloseLarge} style={{ width: "25%" }}>
                <Offcanvas.Body>
                  <div>
                    {error && <p>{error}</p>}
                    <ul className="container navbar-nav d-flex flex-col justify-content-around mt-3" style={{ listStyle: "none" }}>
                      {categories.map((category) => (
                        <li className="nav-item dropdown position-relative " style={{ borderBottom: "1px solid #ccc" }} key={category._id}
                          onMouseEnter={() => fetchSubcategories(category.catName, category._id)}
                        >
                          <Link
                            to={`/productpage/${category.catName}`}
                            className="nav-link"

                            id={`navbarDropdownMenuLink-${category._id}`}
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            onClick={() => handleCategoryClick(category.catName)} // Call handleCategorySelect with category.catName
                          >
                            {category.catName}
                          </Link>

                          <ul className="dropdown-menu position-absolute  border-0" style={{ left: '45%', top: '0' }} aria-labelledby={`navbarDropdownMenuLink-${category._id}`}>
                            {subcategories[category._id]?.map((subcategory) => (
                              <li key={subcategory._id}>
                                <Link
                                  to={`/productpage/${category.catName}/${subcategory.subCatName}`}
                                  className="dropdown-item"
                                  onClick={() => handlesubcategoryClick(category.catName, subcategory.subCatName)} // Pass both category and subcategory
                                >
                                  {subcategory.subCatName}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </li>
                      ))}
                    </ul>
                  </div>
                </Offcanvas.Body>
              </Offcanvas>
            </div>
          </div>
          <div className='col-lg-2  col-md-6 d-flex justify-contendt-aroun p-0'>
            <div className='p-0 d-flex h-32'>
              <Link to='/'><img src='/images/navjlogo.png ' type="png" className=' img-fluid my-1 ' style={{ height: "70px", width: "230px" }} /></Link>
            </div>
          </div>
          {/* search bar start */}
          <div className='col-lg-4 col-md-6 mt-3 p-0 position-relative'>
            <div className="container mt-1 d-flex">
              <div className='bg-white border d-flex align-items-center' style={{ height: "30px", width: "95%", padding: "0 5px" }}>
                <DropdownButton variant="" title={selectedItem} id="dropdown-basic" className='border-0'>
                  <Dropdown.Item onClick={() => handleItemClick('All Catogery')}>All Catogery</Dropdown.Item>
                  <Dropdown.Item onClick={() => handleItemClick('Mobiles')}>Mobiles</Dropdown.Item>
                  <Dropdown.Item onClick={() => handleItemClick('Smart Watch')}>Smart Watch</Dropdown.Item>
                  <Dropdown.Item onClick={() => handleItemClick('TV and Audio')}>TV and Audio</Dropdown.Item>
                  <Dropdown.Item onClick={() => handleItemClick('Laptop')}>Laptop</Dropdown.Item>
                  <Dropdown.Item onClick={() => handleItemClick('Accessories')}>Accessories</Dropdown.Item>
                </DropdownButton>
                <input
                  type='text'
                  className='bg-white border-0 ms-2 flex-grow-1'
                  style={{ outline: "none" }}
                  placeholder="Search..."
                  value={searchQuery}
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDown}
                />
                <i
                  className="fa-solid fa-search ms-2 fs-5 text-muted"
                  style={{ cursor: "pointer" }}
                  onClick={handleSearchIconClick}
                ></i>

              </div>
              <Link to="/advancesearch"><i class="fa-solid fa-gear ms-2 mt-2" data-tooltip-id="my-tooltip-1" style={{ color: "white" }}></i></Link>
              <ReactTooltip
                id="my-tooltip-1"
                place="bottom"
                content="Advance Search"
              />
            </div>
            {/* Display search results */}
            <div className="mt-0 position-absolute" style={{ top: "40%", left: 0, zIndex: 9999, backgroundColor: "white", width: "100%", maxHeight: "300px", overflowY: "auto", boxShadow: "0px 8px 16px 0px rgba(0,0,0,0.2)" }}>
              {searchResults.map((result, index) => (
                <Link to={`/productdetails/${result.productId}`} key={index} className="text-decoration-none text-dark">
                  <div className='card mb-0'>
                    <div className='d-flex align-items-center'>
                      <img src={`https://jinendra.online/${result.prodimg1}`} alt={result.productName} style={{ width: '100px', height: '80px', objectFit: 'contain' }} />
                      <div className='ms-2'>
                        <div>{result.productName}</div>
                        <div className=''>&#8377;{result.discountedPrice}  <span className='ms-3'>&#8377;<del>{result.orgPrice}</del></span> </div>
                      </div>
                    </div>
                    {/* Divider */}
                    <hr className='m-0' />
                  </div>
                </Link>
              ))}
            </div>
          </div>
          <div className='col-lg-5 col-md-12 m-0 p-0'>
            <ul className='row m-0 p-0' style={{ listStyle: 'none' }}>
              <li className='col-3 mt-2 border-end pt-2 text-center'>
                <Link to="/contactus" className='text-decoration-none text-white '><span className='co'> Need Help?</span></Link>
              </li>
              <li className='col-3 mt-2 border-end p-0 text-center'>
                <Button variant="" className='d-flex' onClick={handleShow3} >
                  <div className="position-relative hoverable-button  ">
                    <i className="fa-solid fa-bag-shopping text-white fs-4 ">
                      {totalQuantity > 0 && (
                        <Badge bg="secondary" className="position-absolute rounded-circle w-75 top-0 start-100 translate-middle p-1" style={{ fontSize: '0.75rem' }}>
                          {totalQuantity}
                        </Badge>
                      )}
                    </i>
                  </div>
                  <span className='ms-2 hoverable1-button'>&#8377;{subtotal}</span>
                </Button>
                <Offcanvas show={show3} onHide={handleClose3} placement="end">
                  <Offcanvas.Header closeButton>
                    <Offcanvas.Title><Link to='/cart' ><img src='/images/export.png' width={25} /></Link><span className='ms-2 text-dark' style={{ fontSize: "13px" }}>GO TO CART</span></Offcanvas.Title>
                  </Offcanvas.Header>
                  <Offcanvas.Body>
                    {cartData.length === 0 ? (
                      <p>Your cart is empty.</p>
                    ) : (
                      <>
                        <table>
                          <tbody>
                            {cartData.map((cartItem) => (
                              <tr key={cartItem.cId} >
                                {/* First column: Product image */}
                                <td style={{ position: 'relative' }}>
                                  <button onClick={() => deleteCartItem(cartItem.cId)} className=' text-white border-0 p-1  m-1' style={{ backgroundColor: "#1D1D1F", position: 'absolute', top: 0, left: 0, height: "30px" }}> <i className="fa-solid fa-x"></i></button>
                                  <img src={`https://jinendra.online/${cartItem.prodimg1}`} style={{ maxHeight: '135px', maxWidth: '135px' }} />
                                </td>
                                {/* Second column: Product name */}
                                <td style={{ width: '40%' }}>
                                  <span>{cartItem.productName}</span>
                                </td>
                                {/* Third column: Price and quantity */}
                                <td style={{ width: '40%' }} className="text-center">
                                  <div>
                                    <div>&#8377;{cartItem.discountedPrice}</div>
                                    <div>QTY: {cartItem.quantity}</div>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </>
                    )}
                  </Offcanvas.Body>
                  <div className='text-center'>
                    <p className='fw-bold fs-3'>Total: &#8377;{subtotal}</p>
                  </div>
                </Offcanvas>
              </li>
              {auth ? (
                <li className='col-2 mt-2 border-end p-0  text-center '>
                  <Link className='text-decoration-none text-white ' to="/wishlist"><i className="fa-regular fa-heart fs-3 co mt-2"></i></Link>
                </li>
              ) : (<li className='col-2 mt-2 border-end p-0 text-center '>
                <Link className='text-decoration-none text-white ' to="/customerlogin"><i className="fa-regular fa-heart fs-3 co mt-2"></i></Link>
              </li>)}<br />

              <li className='col-3 mt-1 p text-center'>
                <Button variant="" onClick={handleShow2} className='border-0  ' style={{ cursor: 'pointer', backgroundColor: 'transparent', borderColor: 'transparent', color: "white", }}>
                  <span className='co' >
                    <span style={{ fontSize: '10px' }}>HELLO, {auth ? auth.firstname : "sign in"} </span><br />
                    <span style={{ fontSize: '12px' }}><b>Your Account</b></span>
                  </span>
                </Button>

                <Offcanvas show={show2} onHide={handleClose2} placement="end">
                  <Offcanvas.Body className="p-0" >

                    {!auth ? (
                      <Form className="p-3" onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" controlId="formGroupEmail">
                          <Form.Label>Phone</Form.Label>
                          <Form.Control
                             type="number"
                            placeholder="Phone"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                          />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formGroupPassword">
                          <Form.Label>Password</Form.Label>
                          <Form.Control
                            type="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                        </Form.Group>
                        <button
                          type="submit"
                          className="rounded-5 w-100 border-0 text-white p-2 mt-2 "
                          style={{ backgroundColor: "#1D1D1F" }}
                        >
                          SIGN IN
                        </button>
                        <Link
                          to="/forgotpass"
                          style={{ marginLeft: "28%", color: "gray" }}
                        >
                          Forgot Your Password?
                        </Link>
                        <div className='mt-3 text-center p-2' style={{ backgroundColor: "#F5F5F5" }}>
                          <span>NEW CUSTOMER?<Link to="/newcustomer" style={{ color: "#1D1D1F", textDecoration: "none" }}> START HERE </Link></span>
                        </div>
                      </Form>
                    ) : (
                      <div className=''>
                        <div className='rounded-circle p-5 text-center' style={{ margin: "50px 130px", backgroundColor: "#1D1D1F" }} >
                          <i class="fa-solid fa-user fs-1 " style={{ color: "white" }}></i>
                        </div>

                        <p className='text-center'>Hii {auth.firstname}</p>
                        <button style={{ marginLeft: "38%" }} className='bg-white border-0 fw-bold fs-5' onClick={() => userGetLogout()}>Sign Out</button>

                      </div>
                    )}
                    <ul style={{ listStyle: "none", marginTop: "25px" }}>
                      <li className='mb-2' style={{ fontSize: "14px" }}>
                        {auth ? (
                          <Link to="/myaccount" style={{ textDecoration: "none", color: "black" }}>
                            <i className="fa-solid fa-user me-3"></i>MY ACCOUNT
                          </Link>
                        ) : (
                          <Link to="#" style={{ textDecoration: "none", color: "black" }} onClick={handleUnauthorizedAccess}>
                            <i className="fa-solid fa-user me-3"></i>MY ACCOUNT
                          </Link>
                        )}
                      </li>

                      <li className='mb-2' style={{ fontSize: "14px" }}>
                        {auth ? (
                          <Link to='/myorders' style={{ textDecoration: "none", color: "black" }}>
                            <i className="fa-solid fa-receipt me-3"></i>MY ORDER
                          </Link>
                        ) : (
                          <a href="#" style={{ textDecoration: "none", color: "black" }} onClick={handleUnauthorizedAccess}>
                            <i className="fa-solid fa-receipt me-3"></i>MY ORDER
                          </a>
                        )}
                      </li>
                      <li className='mb-2' style={{ fontSize: '14px' }}>
                        {auth ? (
                          <Link to='/wishlist' style={{ textDecoration: 'none', color: 'black' }}>
                            <i class="fa-solid fa-heart me-3"></i>WISHLIST
                          </Link>
                        ) : (
                          <button onClick={handleWishlistClick} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
                            <i class="fa-solid fa-heart me-3"></i>WISHLIST
                          </button>
                        )}
                      </li>

                      <li className='mb-2' style={{ fontSize: "14px" }}>
                        <Link to='/compareproducts' style={{ textDecoration: "none", color: "black" }}>
                          <i className="fa-solid fa-code-compare me-3"></i>COMPAIR
                        </Link>
                      </li>

                      <li className='mb-2' style={{ fontSize: "14px" }}>
                        <Link to='/contactus' style={{ textDecoration: "none", color: "black" }}>
                          <i className="fa-regular fa-envelope me-3"></i>CONTACT US
                        </Link>
                      </li>
                    </ul>
                  </Offcanvas.Body>
                </Offcanvas>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* nav at top */}
      <div className='d-lg-none' style={{ backgroundColor: "#1D1D1F" }}>
        <div className='row d-flex justify-content-around px-md-5  '>
          <div className='col-md-3 col-2'>
            <Button variant="" onClick={handleShow}>
              <i className="fa-solid fa-bars fs-4 mt-2 text-white"></i>
            </Button>

            <Offcanvas show={show} className="w-75" onHide={handleClose}>
              <Offcanvas.Header closeButton>
                <Offcanvas.Title></Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <div>
                  <button onClick={toggleViews} className=' border-1 w-50 rounded-5 text-white ' style={{ border: "1px solid #1D1D1F", backgroundColor: "#1D1D1F", marginLeft: "25%" }}>{view1Visible ? "Account " : "Menu"}</button>

                  {view1Visible ?
                    <div>
                      {error && <p>{error}</p>}
                      <ul className="container navbar-nav d-flex flex-col justify-content-around mt-3" style={{ listStyle: "none" }}>
                        {categories.map((category) => (
                          <li className="nav-item dropdown position-relative" key={category._id}
                            onMouseEnter={() => fetchSubcategories(category.catName, category._id)}
                          >
                            <Link
                              to={`/productpage/${category.catName}`}
                              className="nav-link"
                              id={`navbarDropdownMenuLink-${category._id}`}
                              role="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                              onClick={() => handleCategoryClick(category.catName)} // Call handleCategorySelect with category.catName
                            >
                              {category.catName}
                            </Link>

                            <ul className="dropdown-menu position-absolute" style={{ left: '40%', top: '0' }} aria-labelledby={`navbarDropdownMenuLink-${category._id}`}>
                              {subcategories[category._id]?.map((subcategory) => (
                                <li key={subcategory._id}>
                                  <Link
                                    to={`/productpage/${category.catName}/${subcategory.subCatName}`}
                                    className="dropdown-item"
                                    onClick={() => handlesubcategoryClick(category.catName, subcategory.subCatName)} // Pass both category and subcategory
                                  >
                                    {subcategory.subCatName}
                                  </Link>
                                </li>
                              ))}
                            </ul>
                          </li>
                        ))}
                      </ul>
                    </div> :
                    <div>
                      <p className='fs-4 text-center mt-2'>WELCOME TO JINENDRA</p>
                      <ul style={{ listStyle: "none", lineHeight: "50px" }} >
                        {auth ? (
                          <li>
                            <Link to='/myaccount' style={{ textDecoration: "none", color: "black" }}>My Account</Link>
                          </li>
                        ) : <Link to='/customerlogin' style={{ textDecoration: "none", color: "black" }}>My Account</Link>}<br />

                        {auth ? (
                          <Link to='/myorders' style={{ textDecoration: "none", color: "black" }}>
                            My Order
                          </Link>
                        ) : <Link to='/customerlogin' style={{ textDecoration: "none", color: "black" }}>My Order</Link>}<br />

                        {auth ? (
                          <Link to='/wishlist' style={{ textDecoration: "none", color: "black" }}>
                            Wishlist
                          </Link>
                        ) : <Link to='/customerlogin' style={{ textDecoration: "none", color: "black" }}>Wishlist</Link>}<br />


                        <Link to='/compareproducts' style={{ textDecoration: "none", color: "black" }}>
                          Compair
                        </Link>


                        {auth ? (
                          <li>
                            <button className='border-0 bg-white p-0' onClick={() => userGetLogout()}><b>Sign Out</b></button>
                          </li>
                        ) : (
                          <li>
                            <Link to='/customerlogin' style={{ textDecoration: "none", color: "black" }}>Sign In</Link><br />
                            <Link to='/newcustomer' style={{ textDecoration: "none", color: "black" }}>Create An Account</Link>
                          </li>
                        )}
                      </ul>
                    </div>}
                </div>
              </Offcanvas.Body>
            </Offcanvas>
          </div>
          <div className='col-md-6 col-3 text-center d-flex p-0' style={{ width: "35%" }}>
            <img src='/images/navjlogo.png ' className='img-fluid ' />
          </div>

          <div className='col-md-3 col-4 d-flex align-items-center justify-content-end  '>
            <Dropdown >
              <Dropdown.Toggle variant="">
                <i className="fa-solid fa-magnifying-glass mt-2 fs-5 text-white" style={{ marginRight: "2px" }} ></i>
              </Dropdown.Toggle>

              <Dropdown.Menu style={{ width: "500%" }}  >

                <div className='bg-white border rounded-3 d-flex ' style={{ height: "30px", width: "95%" }}>
                  <input
                    type='text'
                    className='bg-white border-0 mt-1 ms-2 position-relative'
                    style={{ width: "100%", outline: "none" }}
                    placeholder="Search..."
                    value={searchQuery}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                  />
                  <i
                    className="fa-solid fa-search mt-2 fs-5 text-muted"
                    style={{ marginRight: "10px", cursor: "pointer" }}
                    onClick={handleSearchIconClick}
                  ></i>
                  <div className="mt-0 position-absolute" style={{ top: "100%", left: 0, zIndex: 9999, backgroundColor: "white", width: "100%", maxHeight: "300px", overflowY: "auto", boxShadow: "0px 8px 16px 0px rgba(0,0,0,0.2)" }}>
                    {searchResults.map((result, index) => (
                      <Link to={`/productdetails/${result.productId}`} key={index} className="text-decoration-none text-dark">
                        <div className='card mb-0'>
                          <div className='d-flex align-items-center'>
                            <img src={`https://jinendra.online/${result.prodimg1}`} alt={result.productName} style={{ width: '100px', height: '80px', objectFit: 'contain' }} />
                            <div className='ms-2'>
                              <div>{result.productName}</div>
                              <div className=''>&#8377;{result.discountedPrice}  <span className='ms-3'>&#8377;<del>{result.orgPrice}</del></span> </div>
                            </div>
                          </div>
                          {/* Divider */}
                          <hr className='m-0' />
                        </div>
                      </Link>
                    ))}
                  </div>
                </div>
              </Dropdown.Menu>
            </Dropdown>
            <div className='mt-3'>
              <Link to={!auth ? '/customerlogin' : '/cart'}>
                <i className="fa-solid fa-cart-shopping fs-4 text-white"></i>
              </Link>
            </div>

          </div>
        </div>
      </div>
      {/* nav at bottom */}
      <div className='d-lg-none d-md-none   '>
        <div className='d-flex justify-content-evenly fixed-bottom pt-2 w-100 ' style={{ backgroundColor: "#1D1D1F", zIndex: 999, height: "65px" }}>
          <Link to="/"> <i class="fa-solid fa-house text-white fs-5"></i></Link>
          <Link><i class="fa-brands fa-whatsapp text-white  fs-5"></i></Link>
          <div>
            <Link to={!auth ? '/customerlogin' : '/cart'}>
              <i className="fa-solid fa-cart-shopping fs-4 text-white"></i>
            </Link>
          </div>
          <Link to='/contactus'><i class="fa-solid fa-location-dot text-white fs-5"></i></Link>
        </div>
      </div>
      <div className='d-none d-md-block'>
        {error && <p>{error}</p>}
        <ul className="container navbar-nav d-flex flex-row justify-content-around text-center mt-3" style={{ listStyle: "none" }}>
          {categories.map((category) => (
            <li className="nav-item dropdown position-relative" key={category._id} onMouseEnter={() => fetchSubcategories(category.catName, category._id)}>
              <img src={category.img} className='' style={{ width: "50px", height: "50px" }} />
              <Link
                to={`/productpage/${category.catName}`}
                className="nav-link"
                id={`navbarDropdownMenuLink-${category._id}`}
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                onClick={() => handleCategoryClick(category.catName)} // Call handleCategorySelect with category.catName
              >
                {category.catName}
              </Link>
              <ul className="dropdown-menu position-absolute border-0" aria-labelledby={`navbarDropdownMenuLink-${category._id}`}>
                {subcategories[category._id]?.map((subcategory) => (
                  <li key={subcategory._id}>
                    <Link
                      to={`/productpage/${category.catName}/${subcategory.subCatName}`}
                      className="dropdown-item"
                      onClick={() => handlesubcategoryClick(category.catName, subcategory.subCatName)} // Pass both category and subcategory
                    >
                      {subcategory.subCatName}
                    </Link>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </div>
    </>
  )
}

export default Header1;
