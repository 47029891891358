import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Products.css';
import Dropdown from 'react-bootstrap/Dropdown';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Link } from 'react-router-dom';
import ProductDetailPopup from './ProductDetailPopup';
import axios from 'axios'
import { productAddedToCart } from './ProductDetail';
import { handleAddToCompare } from './ProductDetail'

function Products({ products }) {
  const [selectedItem, setSelectedItem] = useState('Position');
  const [sortedProducts, setSortedProducts] = useState(products);

  const handleItemClick = (item) => {
    setSelectedItem(item);

    let sortedList = [...products];

    switch (item) {
      case 'Position':
        // Sort by position logic (if applicable)
        break;
      case 'Product Name':
        sortedList.sort((a, b) => a.title.localeCompare(b.title));
        break;
      case 'price':
        sortedList.sort((a, b) => a.price - b.price);
        break;
      case 'Color':
        // Sort by color logic (if applicable)
        break;
      case 'Internal Memory':
        // Sort by internal memory logic (if applicable)
        break;
      case 'Varent':
        // Sort by variant logic (if applicable)
        break;
      case 'Display Size':
        // Sort by display size logic (if applicable)
        break;
      case 'Rating':
        // Sort by rating logic (if applicable)
        break;
      default:
        // Default case or do nothing
        break;
    }
    setSortedProducts(sortedList);
  };

  const [count, setCount] = useState(1); // Set initial count to 1
  const increment = () => {
    setCount(prevCount => prevCount + 1);
  };

  const decrement = () => {
    // Check if count is greater than 1 before decrementing
    if (count > 1) {
      setCount(prevCount => prevCount - 1);
    }
  };
  useEffect(() => {
    localStorage.setItem('count', count.toString());
  }, [count]);

  const [product1, setProduct1] = useState(null);


  useEffect(() => {
    const fetchProductsDetails = async () => {
      try {
        const response = await axios.get('https://jinendra.online/api/products_Productsdetails');
        console.log('Fetched product1 data:', response.data.data1);
        setProduct1(response.data.data1); // Set product1 to response.data.data1
      } catch (error) {
        console.error('Error fetching product1:', error);
      }
    };

    fetchProductsDetails();
  }, []);

  const addToWishlist = async (product) => {
    // Check if the user is logged in
    const auth = localStorage.getItem('user');
    const userId = auth ? JSON.parse(auth).userId : null;

    if (!userId) {
      alert('Please login to add products to wishlist');
      return;
    }

    try {
      // Make the POST request to add the product to the wishlist
      const response = await axios.post('https://jinendra.online/api/addwishlist', {
        ...product,
        userId: userId // Include the user ID in the product data
      });

      if (response.status === 201) {

        // console.log('Product added to wishlist successfully');
      } else {
        console.error('Failed to add product to wishlist:', response.data.message);
        // Handle the error
      }

      alert("Product add to wishlist")
    } catch (error) {
      console.error('Error adding product to wishlist:', error);
      // Handle network errors or other exceptions
    }
  };

  const [selectedIcon, setSelectedIcon] = useState('grid'); // Initialize selected icon as grid

  const handleIconClick = (icon) => {
    setSelectedIcon(icon);
  };

  return (
    <div>


      <div className='d-flex justify-content-between mb-3' style={{ backgroundColor: "white", height: "47.6px", marginTop: "25px" }}>
        <div className='border m-2 bg-black'>
          {/* Grid view icon */}
          <i
            className={`fa-solid fa-table-cells fs-4 p-1 ${selectedIcon === 'grid' ? 'text-danger' : 'text-white'}`}
            onClick={() => handleIconClick('grid')}
            style={{ backgroundColor: selectedIcon === 'grid' ? 'white' : 'transparent', color: selectedIcon === 'grid' ? 'black' : 'white' }}
          ></i>
          {/* List view icon */}
          <i
            className={`fa-solid fa-list fs-4 p-1 ${selectedIcon === 'list' ? 'text-danger' : 'text-white'}`}
            onClick={() => handleIconClick('list')}
            style={{ backgroundColor: selectedIcon === 'list' ? 'white' : 'transparent', color: selectedIcon === 'list' ? 'black' : 'white' }}
          ></i>
        </div>

        <Dropdown className='ms-5 pt-1 me-3 '>
          <span>Sort By :</span>
          <Dropdown.Toggle variant="" className='border ms-1' id="dropdown-basic">
            {selectedItem}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item href="#/action-1" onClick={() => handleItemClick("Position")} >Position</Dropdown.Item>
            <Dropdown.Item href="#/action-2" onClick={() => handleItemClick("Product Name")}>Product Name</Dropdown.Item>
            <Dropdown.Item href="#/action-3" onClick={() => handleItemClick("price")}>Price</Dropdown.Item>
            <Dropdown.Item href="#/action-1" onClick={() => handleItemClick("Color")}>Color</Dropdown.Item>
            <Dropdown.Item href="#/action-2" onClick={() => handleItemClick("Internal Memory")}>Internal Memory</Dropdown.Item>
            <Dropdown.Item href="#/action-3" onClick={() => handleItemClick("Varent")}>Variant</Dropdown.Item>
            <Dropdown.Item href="#/action-1" onClick={() => handleItemClick("Display Size")}>Display Size</Dropdown.Item>
            <Dropdown.Item href="#/action-2" onClick={() => handleItemClick("Rating")}>Rating</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>

      <div className="row row-cols-2 row-cols-md-3 row-cols-lg-4  ">
        {products.map((product) => (
          <div key={product.key} className="position-relative p-lg-2 p-1">
            <div className="card product-card px-0"> {/* Added p-0 class to remove padding */}
              <Link to={`/productdetails/${product.productId}`}>
                <img
                  src={`https://jinendra.online/${product.prodimg1}`}
                  className="card-img-top img-fluid"
                  alt={product.title}
                />
                <div className="overlay"></div> {/* This overlay will be used for hover effect */}
              </Link>
              <div className="card-body">
                <div className="product-details text-center">
                  <h5 className="card-title">{product.productName}</h5>
                  <div className="price-info d-flex flex-column flex-md-row justify-content-between pt-3">
                    <p className="card-text old-price mb-3 mb-md-0">₹{product.orgPrice}</p>
                    <p className="card-text text-dark">₹{product.discountedPrice}</p>
                  </div>

                  <div className='d-none d-lg-block'>
                    <div className="add-to-cart-btn">
                      {/* Your add to cart buttons */}
                      <div className='row justify-content-center'>

                        <div className='col-12 col-md-6'>
                          <div className="input-group mb-3 border rounded-5">
                            <button onClick={decrement} className="btn btn-white " type="button" id="button-addon1" data-mdb-ripple-color="dark">
                              <i className="fas fa-minus" style={{ color: "gray" }}></i>
                            </button>
                            <strong className='mt-2  '>{count}</strong>
                            <button onClick={increment} className="btn btn-white " type="button" id="button-addon2" data-mdb-ripple-color="dark">
                              <i className="fas fa-plus" style={{ color: "gray" }}></i>
                            </button>
                          </div>
                        </div>
                        <div className='col-12 col-md-6'>
                          <div className='row justify-content-center'>

                            <div className='col-4 d-flex justify-content-center'>
                              <Link to={`/cart`} className="d-block text-center">
                                <button className="btn btn-primary buy-btns" type="button" style={{ backgroundColor: 'black', color: 'white', width: '100%' }} onClick={() => productAddedToCart(product, count)}>
                                  <i className="fa-solid fa-bag-shopping co"></i>
                                </button>
                              </Link>
                            </div>

                            <div className='col-4 d-flex justify-content-center'>
                              <div className='rounded-circle btn btn-primary buy-btns' style={{ cursor: 'pointer', backgroundColor: 'black', color: 'white', width: '35px', height: '35px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => addToWishlist(product, product1)}>

                                <i class="fa-solid fa-heart co"></i>
                              </div>
                            </div>

                            <div className='col-4 d-flex justify-content-center'>
                              <div className='rounded-circle btn btn-primary buy-btns' style={{ cursor: 'pointer', backgroundColor: 'black', color: 'white', width: '35px', height: '35px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => handleAddToCompare(product)}>
                                <i className="fa-solid fa-arrow-right-arrow-left co"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* small devices */}
                  <div className="col-12  d-block d-lg-none ">
                    <div className="row justify-content-between mt-2 ">
                      {/* Cart Button */}
                      <div className="col-4 d-flex justify-content-center">
                        <Link to={`/cart`} className="d-block text-center">
                          <button className="btn btn-primary buy-btns" type="button" style={{ backgroundColor: 'black', color: 'white', width: '100%' }} onClick={() => productAddedToCart(product, count)}>
                            <i className="fa-solid fa-bag-shopping co"></i>
                          </button>
                        </Link>
                      </div>
                      {/* Wishlist Button */}
                      <div className="col-4 d-flex justify-content-center">
                        <div className="rounded-circle btn btn-primary buy-btns" style={{ cursor: 'pointer', backgroundColor: 'black', color: 'white', width: '35px', height: '35px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => addToWishlist(product, product1)}>
                          <i className="fa-solid fa-heart co"></i>
                        </div>
                      </div>
                      {/* Compare Button */}
                      <div className="col-4 d-flex justify-content-center">
                        <div className="rounded-circle btn btn-primary buy-btns" style={{ cursor: 'pointer', backgroundColor: 'black', color: 'white', width: '35px', height: '35px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => handleAddToCompare(product)}>
                          <i className="fa-solid fa-arrow-right-arrow-left co"></i>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
                <ProductDetailPopup
                  productId={product.productId}
                  products={products}
                  deactivateLinkEffect={true}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Products;