import React, { useEffect, useState } from 'react'
import Header1 from './Header1'
import Footer from './Footer'
import SliderCarousel from './SliderCarousel'
import { Link } from 'react-router-dom';
import SocialMediaIcons from './SocialMediaIcons';
import { Button, Offcanvas } from 'react-bootstrap';

function Home() {
  const [showCart, setShowCart] = useState(false);
  const [cartData, setCartData] = useState([])

  const [userId, setUserId] = useState(null);

  const handleCloseCart = () => setShowCart(false);
  const handleShowCart = () => setShowCart(true);

  const [subtotal, setSubtotal] = useState(0); // State to hold subtotal
  useEffect(() => {
    calculateSubtotal();
  }, [cartData]);

  const calculateSubtotal = () => {
    let total = 0;
    cartData.forEach((item) => {
      total += item.discountedPrice * item.quantity;
    });
    setSubtotal(total);
  };

  const totalQuantity = cartData.reduce((total, cartItem) => total + cartItem.quantity, 0);

  useEffect(() => {
    const userData = localStorage.getItem('user');
    if (userData) {
      const { userId } = JSON.parse(userData);
      setUserId(userId);
    }
  }, []);

  useEffect(() => {
    const fetchCartData = async () => {
      try {
        const response = await fetch(`https://jinendra.online/api/showusercart/${userId}`);
        if (response.ok) {
          const cartProductData = await response.json();
          setCartData(cartProductData.data || []);
        } else {
          throw new Error('Failed to fetch cart data');
        }
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    if (userId) { // Check if userId is available before fetching cart data
      fetchCartData();
    }
  }, [userId]);

  // delet api
  const deleteCartItem = async (cartId) => {
    try {
      const response = await fetch(`https://jinendra.online/api/deletecart/${cartId}`, {
        method: 'DELETE', // Use DELETE method for deleting data
      });
      if (!response.ok) {
        throw new Error(`Failed to delete cart item. Status: ${response.status} ${response.statusText}`);
      }
      const data = await response.json();
      setCartData(prevCartData => prevCartData.filter(item => item.cId !== cartId));
    } catch (error) {
    }
  }

  return (
    <div>
      <Header1 />
      <SocialMediaIcons />
      <SliderCarousel />
      <div className='d-none d-lg-block w-50' style={{ position: 'fixed', bottom: 0, right: 1300, left: 0, zIndex: 999 }}>
        <Button variant="" onClick={handleShowCart} className="d-flex border-0">
          <div className="position-relative hoverable-button bg-dark text-white " >
            <p className='px-2 mt-1 text-center'>
              {cartData.length === 0 ? (
                <span>Your cart is empty.</span>
              ) : (
                <span>{totalQuantity} items in your cart</span>
              )}
            </p>
          </div>
        </Button>
        <Offcanvas show={showCart} style={{ height: "35%" }} onHide={handleCloseCart} placement="bottom">
          <Offcanvas.Header >

          </Offcanvas.Header>
          <Offcanvas.Body>
            {cartData.length === 0 ? (
              <p>Your cart is empty.</p>
            ) : (
              <div className='d-flex justify-content-between'>
                <table  >
                  <tbody className='d-flex'>
                    {cartData.map((cartItem) => (
                      <tr key={cartItem.cId} >
                        {/* First column: Product image */}
                        <td style={{ position: 'relative' }}>
                          <button onClick={() => deleteCartItem(cartItem.cId)} className="text-white border-0 p-1 m-1" style={{ backgroundColor: "#1D1D1F", position: 'absolute', top: 0, left: 0, height: "30px" }}> <i className="fa-solid fa-x"></i></button>
                          <img src={`https://jinendra.online/${cartItem.prodimg1}`} style={{ maxHeight: '135px', maxWidth: '135px' }} alt="Product" />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className=''>
                  <span >Cart Summary
                    <spna className='  ms-5'>
                      <Link to="/cart">
                        <img src="/images/export.png" width={25} alt="Export Icon" />
                      </Link>
                    </spna>
                  </span>
                  <p className='mt-3   '>Total Quantity:&nbsp;<span className=' ms-5'>{totalQuantity}</span> </p>
                  <p className=''>Total Subtotal: <span className='fw-bold  '> &nbsp;&#8377;{subtotal}</span> </p>
                </div>
              </div>
            )}
          </Offcanvas.Body>
        </Offcanvas>
      </div>
      <Footer />
    </div>
  )
}

export default Home;