// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* CompareProducts.css */

/* Style for remove button */
.btn-remove {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: black;
  color: white;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

/* Card styles */
.card {
  position: relative;
  /* Ensure position is relative for absolute positioning of the remove button */
  margin-bottom: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

.card:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.card img {
  width: 100%;
  height: auto;
}

.card-body {
  padding: 20px;
}

.card-title {
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

.card-text {
  color: #666;
}

/* Table styles */
.table {
  width: 100%;
  border-collapse: collapse;
}

.table th,
.table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.table th {
  background-color: #f2f2f2;
}`, "",{"version":3,"sources":["webpack://./src/Components/CompareProducts.css"],"names":[],"mappings":"AAAA,wBAAwB;;AAExB,4BAA4B;AAC5B;EACE,kBAAkB;EAClB,QAAQ;EACR,UAAU;EACV,uBAAuB;EACvB,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,eAAe;AACjB;;AAEA,gBAAgB;AAChB;EACE,kBAAkB;EAClB,8EAA8E;EAC9E,mBAAmB;EACnB,wCAAwC;EACxC,gCAAgC;AAClC;;AAEA;EACE,yCAAyC;AAC3C;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,eAAe;EACf,qBAAqB;AACvB;;AAEA;EACE,WAAW;AACb;;AAEA,iBAAiB;AACjB;EACE,WAAW;EACX,yBAAyB;AAC3B;;AAEA;;EAEE,sBAAsB;EACtB,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":["/* CompareProducts.css */\n\n/* Style for remove button */\n.btn-remove {\n  position: absolute;\n  top: 5px;\n  right: 5px;\n  background-color: black;\n  color: white;\n  border: none;\n  border-radius: 50%;\n  width: 30px;\n  height: 30px;\n  cursor: pointer;\n}\n\n/* Card styles */\n.card {\n  position: relative;\n  /* Ensure position is relative for absolute positioning of the remove button */\n  margin-bottom: 20px;\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n  transition: box-shadow 0.3s ease;\n}\n\n.card:hover {\n  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);\n}\n\n.card img {\n  width: 100%;\n  height: auto;\n}\n\n.card-body {\n  padding: 20px;\n}\n\n.card-title {\n  font-size: 1rem;\n  margin-bottom: 0.5rem;\n}\n\n.card-text {\n  color: #666;\n}\n\n/* Table styles */\n.table {\n  width: 100%;\n  border-collapse: collapse;\n}\n\n.table th,\n.table td {\n  border: 1px solid #ddd;\n  padding: 8px;\n  text-align: left;\n}\n\n.table th {\n  background-color: #f2f2f2;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
