import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { productAddedToCart } from './ProductDetail';
import Header1 from './Header1';
import SocialMediaIcons from './SocialMediaIcons';
import Footer from './Footer';

function Wishlist() {
  const [wishlist, setWishlist] = useState([]);
  const [loggedInUser, setLoggedInUser] = useState(null); // State to store logged-in user information

  useEffect(() => {
    // Simulate fetching logged-in user information (replace this with your actual authentication logic)
    const user = localStorage.getItem('user');
    if (user) {
      setLoggedInUser(JSON.parse(user));
    }

    async function fetchWishlist() {
      try {
        const response = await fetch('https://jinendra.online/api/wishlist');
        if (!response.ok) {
          throw new Error('Failed to fetch wishlist');
        }
        const data = await response.json();
        setWishlist(data.wishlist);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }

    fetchWishlist();

    // Clean up function
    return () => {
      // Any cleanup code goes here if needed
    };
  }, []);

  const [count, setCount] = useState(1); // Set initial count to 1
  const increment = () => {
    setCount(prevCount => prevCount + 1);
  };

  const decrement = () => {
    // Check if count is greater than 1 before decrementing
    if (count > 1) {
      setCount(prevCount => prevCount - 1);
    }
  };
  useEffect(() => {
    localStorage.setItem('count', count.toString());
  }, [count]);

  const removeFromWishlist = async (productId) => {
    try {
      const response = await fetch(`https://jinendra.online/api/wishlist/${productId}`, {
        method: 'DELETE'
      });
      if (!response.ok) {
        throw new Error('Failed to remove product from wishlist');
      }
      // If deletion is successful, update the wishlist state
      setWishlist(prevWishlist => prevWishlist.filter(product => product.productId !== productId));
      // console.log("remove from wishlist")
    } catch (error) {
      console.error('Error removing product from wishlist:', error);
    }
  };

  const addToCartAndRemoveFromWishlist = async (product, count) => {
    try {
      // Add the product to the cart
      const response = await productAddedToCart(product, count);
      // console.log("Response from adding to cart:", response); // Log the response
      if (!response.ok) {
        throw new Error('Failed to add product to cart');
      }

      // If adding to cart is successful, remove the product from the wishlist
      removeFromWishlist(product.productId);
      // console.log("Removed from wishlist");
    } catch (error) {
      console.error('Error adding product to cart:', error);
    }
  };


  return (
    <>
      <Header1 />
      <SocialMediaIcons />
      <div style={{ backgroundColor: "#F5F3EA" }}>
        <div className='container' >
          <h1>Wishlist</h1>
          <div className="row g-3">
            {wishlist.map(product => (
              // Render products belonging to the logged-in user only
              loggedInUser && product.userId === loggedInUser.userId && (
                <div key={product.productId} className="col-lg-3 col-md-6 col-sm-12 mb-5 ">
                  <div className="card product-card p-2">
                    <div className='col-4 d-flex justify-content-center'>
                      <div className='rounded-circle btn btn-primary buy-btns' style={{ cursor: 'pointer', backgroundColor: 'black', color: 'white', width: '35px', height: '35px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => removeFromWishlist(product.productId)}>
                        <i class="fa-solid fa-x"></i>
                      </div>
                    </div>

                    <Link to={`/productdetails/${product.productId}`}><img src={`https://jinendra.online/${product.prodimg1}`} className="card-img-top img-fluid" alt={product.title} />

                    </Link>
                    <div className="card-body">
                      <div className="product-details text-center">
                        <h5 className="card-title">{product.productName}</h5>
                        <div className="price-info d-flex justify-content-evenly pt-3">
                          <p className="card-text old-price"> ₹ {product.orgPrice}</p>
                          <p className="card-text">₹ {product.discountedPrice}</p>
                        </div>
                        {/* Add to cart button and other actions */}
                        <div className='add-to-cart-btn'>
                          <div className='row justify-content-center'>
                            <div className='col-md-6 d-flex justify-content-center'>
                              <div className="mb-3 border rounded-5 d-flex">
                                <button onClick={decrement} className="btn btn-white" type="button" id="button-addon1" data-mdb-ripple-color="dark">
                                  <i className="fas fa-minus" style={{ color: "gray" }}></i>
                                </button>
                                <strong className='mt-2 mx-3'>{count}</strong>
                                <button onClick={increment} className="btn btn-white" type="button" id="button-addon2" data-mdb-ripple-color="dark">
                                  <i className="fas fa-plus" style={{ color: "gray" }}></i>
                                </button>
                              </div>
                            </div>

                            <div className='col-md-6 d-flex justify-content-center'>
                              <div className='row justify-content-center'>
                                <div className='col-4 d-flex justify-content-center'>
                                  <Link to={`/cart`} className="d-block text-center">
                                    <button
                                      className="btn btn-primary buy-btns"
                                      type="button"
                                      style={{ backgroundColor: 'black', color: 'white', width: '100%' }}
                                      onClick={() => {
                                        addToCartAndRemoveFromWishlist(product, count); // Add product to cart and remove from wishlist
                                      }}
                                    >
                                      <i className="fa-solid fa-bag-shopping"></i>
                                    </button>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Wishlist;