import React, { useState } from 'react';
import { BsCreditCard, BsCartCheck } from 'react-icons/bs';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header1 from './Header1';
import Footer from './Footer';
import { useLocation } from 'react-router-dom';
import axios from 'axios'
import Popup from './Popup';
import SocialMediaIcons from './SocialMediaIcons';

function Checkout() {
  const location = useLocation();
  const { cartData, subtotal } = location.state;

  const [formData, setFormData] = useState({
    email: '',
    firstName: '',
    lastName: '',
    address: '',
    state: '',
    city: '',
    postalCode: '',
    mobileNumber: '',
    comments: '',
    paymentMethod: '',
  });

  const [formErrors, setFormErrors] = useState({
    email: '',
    firstName: '',
    lastName: '',
    address: '',
    state: '',
    city: '',
    postalCode: '',
    mobileNumber: '',
    paymentMethod: '',
    comments: '',
  });

  const handleChange = e => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validateForm = () => {
    // Perform form validation for all fields
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const mobileNumberRegex = /^\d{10}$/;
    let valid = true;
    const newFormErrors = {
      email: '',
      firstName: '',
      lastName: '',
      streetAddress: '',

      state: '',
      city: '',
      postalCode: '',
      mobileNumber: '',
      paymentMethod: '',
      comments: '',
    };

    if (!formData.email || !emailRegex.test(formData.email)) {
      newFormErrors.email = 'Please enter a valid email address';
      valid = false;
    }

    if (!formData.firstName) {
      newFormErrors.firstName = 'First name is required';
      valid = false;
    }

    if (!formData.lastName) {
      newFormErrors.lastName = 'Last name is required';
      valid = false;
    }

    if (!formData.address) {
      newFormErrors.address = 'Street address is required';
      valid = false;
    }

    if (!formData.state) {
      newFormErrors.state = 'State is required';
      valid = false;
    }

    if (!formData.city) {
      newFormErrors.city = 'City is required';
      valid = false;
    }

    if (!formData.postalCode) {
      newFormErrors.postalCode = 'postalCode code is required';
      valid = false;
    }

    if (!formData.mobileNumber || !mobileNumberRegex.test(formData.mobileNumber)) {
      newFormErrors.mobileNumber = 'Please enter a valid 10-digit mobile number';
      valid = false;
    }

    if (!formData.paymentMethod) {
      newFormErrors.paymentMethod = 'Please select a payment method';
      valid = false;
    }

    setFormErrors(newFormErrors);
    return valid;
  };



  const handleSubmit = async (e) => {
    e.preventDefault();

    const isValid = validateForm();
    if (!isValid) {
      return; // Exit early if form is not valid
    }
    const userIds = [...new Set(cartData.map(item => item.userId))];
    try {
      const dataToSend = {
        email: formData.email,
        firstName: formData.firstName,
        lastName: formData.lastName,
        address: formData.address,
        state: formData.state,
        city: formData.city,
        postalCode: formData.postalCode,
        mobileNumber: formData.mobileNumber,
        comments: formData.comments,
        paymentMethod: formData.paymentMethod,
        userId: userIds.join(','), // Directly include userIds
        userIp: formData.userIp,
        paymentDetailsMethod: formData.paymentDetailsMethod,
        productId: cartData.map(item => item.productId).join(','), // Directly include productIds
        variation: formData.variation,
        amount: subtotal, // Calculate total price from cartData
      };

      const response = await fetch('https://jinendra.online/api/addorder', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataToSend),
      });

      if (response.ok) {
        setFormData({
          email: '',
          firstName: '',
          lastName: '',
          address: '',
          state: '',
          city: '',
          postalCode: '',
          mobileNumber: '',
          comments: '',
          paymentMethod: '',
          userId: '',
          userIp: '',
          paymentDetailsMethod: '',
          productId: '',
          variation: '',
          amount: '',
        });

        setShowPopup(true);
        await axios.delete(`https://jinendra.online/api/getCartProductData/${formData.userId}`);
      } else {
        console.error('Error placing order:', response.statusText);
      }
    } catch (error) {
      console.error('Error placing order:', error.message);
    }
  };

  const [showPopup, setShowPopup] = useState(false);

  return (
    <>
      <Header1 />
      <SocialMediaIcons />
      <div style={{ backgroundColor: "#F5F3EA" }}>
        <div className="container">
          <h3 className="text-center py-3">Checkout</h3>
          <div className="row">
            {/* First Column - Shipping Address */}
            <div className="col-md-4">
              <div className="card mb-4">
                <div className="card-header text-white" style={{ backgroundColor: "#1D1D1F" }}><i class="fa-solid fa-location-dot"></i> Shipping Address</div>
                <div className="card-body">
                  <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                      <label htmlFor="email" className="form-label">Email Address</label>
                      <input type="email" className="form-control" id="email" name="email" value={formData.email} onChange={handleChange} required />
                      <div className="text-danger"></div>
                    </div>
                    <div className="row mb-3">
                      <div className="col">
                        <label htmlFor="firstName" className="form-label">First Name</label>
                        <input type="text" className="form-control" id="firstName" name="firstName" value={formData.firstName} onChange={handleChange} required />
                        <div className="text-danger">{formErrors.firstName}</div>
                      </div>
                      <div className="col">
                        <label htmlFor="lastName" className="form-label">Last Name</label>
                        <input type="text" className="form-control" id="lastName" name="lastName" value={formData.lastName} onChange={handleChange} required />
                        <div className="text-danger">{formErrors.lastName}</div>
                      </div>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="address" className="form-label"> Address </label>
                      <input type="text" className="form-control" id="address" name="address" value={formData.address} onChange={handleChange} required />
                      <div className="text-danger">{formErrors.address}</div>
                    </div>
                    <div className="row mb-3">
                      <div className="col">
                        <label htmlFor="state" className="form-label">State</label>
                        <input type="text" className="form-control" id="state" name="state" value={formData.state} onChange={handleChange} required />
                        <div className="text-danger">{formErrors.state}</div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col">
                        <label htmlFor="city" className="form-label">City</label>
                        <input type="text" className="form-control" id="city" name="city" value={formData.city} onChange={handleChange} required />
                        <div className="text-danger">{formErrors.city}</div>
                      </div>
                      <div className="col">
                        <label htmlFor="postalCode" className="form-label">postalCode / Postal Code</label>
                        <input type="text" className="form-control" id="postalCode" name="postalCode" value={formData.postalCode} onChange={handleChange} required />
                        <div className="text-danger">{formErrors.postalCode}</div>
                      </div>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="mobileNumber" className="form-label">Mobile Number</label>
                      <input type="text" className="form-control" id="mobileNumber" name="mobileNumber" value={formData.mobileNumber} onChange={handleChange} required />
                      <div className="text-danger">{formErrors.mobileNumber}</div>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            {/* Second Column - Shipping & Payment Method */}
            <div className="col-md-4">
              <div className="card">
                <div className="card-header  text-white" style={{ backgroundColor: "#1D1D1F" }}><BsCreditCard /> Payment Method</div>
                <div className="card-body">
                  <div className="form-check mb-2">
                    <input className="form-check-input" type="radio" name="paymentMethod" id="cashOnDelivery" value="cashOnDelivery" onChange={handleChange} />
                    <label className="form-check-label" htmlFor="cashOnDelivery">
                      Cash On Delivery
                    </label>
                  </div>
                  <div className="form-check">
                    <input className="form-check-input" disabled type="radio" name="paymentMethod" id="onlinePayment" value="onlinePayment" onChange={handleChange} />
                    <label className="form-check-label" htmlFor="onlinePayment">
                      Pay Onlinen( All UPI  )
                    </label>
                    <div className="text-danger">{formErrors.paymentMethod}</div>
                  </div>
                </div>
              </div>
            </div>

            {/* Third Column - Order Summary */}
            {/* // Inside the third column - Order Summary */}

            <div className="col-md-4">
              <div className="card">
                <div className="card-header  text-white" style={{ backgroundColor: "#1D1D1F" }}><BsCartCheck /> Order Summary</div>
                <div className="card-body">
                  <div className="row">
                    <div className="col">
                      <p>Cart Subtotal</p>
                    </div>
                    <div className="col text-end">
                      <p>₹{subtotal}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <p>Shipping</p>
                    </div>
                    <div className="col text-end">
                      <p>Not yet calculated</p>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col">
                      <p>Order Total</p>
                    </div>
                    <div className="col text-end">
                      <p>₹{subtotal}</p>
                    </div>
                  </div>
                  <hr />
                  <form onSubmit={handleSubmit}>
                    <div className="accordion" id="productAccordion">
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                          <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            {cartData.length} {cartData.length > 1 ? 'items' : 'item'} in cart
                          </button>
                        </h2>
                        <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#productAccordion">
                          <div className="accordion-body">
                            {cartData.map((product, index) => (
                              <div className="row" key={index}>
                                <div className="col-3">
                                  <img src={`https://jinendra.online/${product.prodimg1}`} alt={product.productImg} className="img-thumbnail" />
                                </div>
                                <div className="col">
                                  <p>{product.productName}</p>
                                  <p>Qty: {product.quantity}</p>
                                </div>
                                <div className="col">
                                  <p>₹{product.discountedPrice}</p>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="comments" className="form-label">Comments</label>
                      <textarea className="form-control" id="comments" name="comments" value={formData.comments} onChange={handleChange} ></textarea>
                      <div className="text-danger">{formErrors.comments}</div>
                    </div>
                    <button type="submit" className="btn text-white w-100 rounded-5" style={{ backgroundColor: "#1D1D1F" }}><span className='co'>Place Order</span></button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Popup isVisible={showPopup} onClose={() => setShowPopup(false)} firstName={formData.firstName} />
      <Footer />
    </>
  );
}

export default Checkout;