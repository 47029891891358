import React from 'react'
import { Link } from 'react-router-dom'
import Header1 from './Header1'
import Footer from './Footer'

function ForgotPass() {
  return (
    <>
      <Header1 />
      <div className='pt-5 row justify-content-center' style={{ backgroundColor: "#F5F3EA", padding: "25px" }}>
        <h4 className='text-center mb-4 '>FORGOT YOUR PASSWORD?</h4>
        <div className="p-3 col-lg-6 col-md-6 col-sm-12" style={{ backgroundColor: 'white', Width: '50%' }}>
          <p className='text-center mt-4'>Please enter your email address below to receive a password reset link.</p>
          <form>
            <label for="email">Email</label><br />
            <input type='email' name='email' className='w-100 mb-3 rounded ' style={{ border: "1px solid lightGray", height: "35px" }} required />
            <button type='submit' className='rounded-5 w-100 border-0 text-white p-2 mt-2' style={{ backgroundColor: "#1D1D1F" }}>RESET MY PASSWORD</button>
            <Link to='/customerlogin' className='mt-2 mb-4 ' style={{ display: 'block', textAlign: 'center', color: "black" }}>GO BACK</Link>
          </form>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default ForgotPass;