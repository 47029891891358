
import Accordion from 'react-bootstrap/Accordion';
import React, { useState, useEffect } from 'react';
import { Form, Button, Alert } from 'react-bootstrap';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Header1 from './Header1';
import Footer from './Footer';
import SocialMediaIcons from './SocialMediaIcons';

const Cart = ({ products }) => {
  const [selectedCountry, setSelectedCountry] = useState('India');
  const [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState('');
  const [pincode, setPincode] = useState('');
  const [pincodeError, setPincodeError] = useState('');
  const [validated, setValidated] = useState(false);
  const [formError, setFormError] = useState('');
  const [cartData, setCartData] = useState([])
  const [userId, setUserId] = useState(null);
  let { productId } = useParams();
  const product = products.data.find((product) => product.productId === productId);

  useEffect(() => {
    const userData = localStorage.getItem('user');
    if (userData) {
      const { userId } = JSON.parse(userData);
      setUserId(userId);
    }
  }, []);

  useEffect(() => {
    const fetchCartData = async () => {
      try {
        const response = await fetch(`https://jinendra.online/api/showusercart/${userId}`);
        if (response.ok) {
          const cartProductData = await response.json();
          setCartData(cartProductData.data || []);
        } else {
          throw new Error('Failed to fetch cart data');
        }
      } catch (error) {
        console.error('Error fetching cart data:', error);
      }
    };

    if (userId) { // Check if userId is available before fetching cart data
      fetchCartData();
    }
  }, [userId]);

  useEffect(() => {
    const fetchStates = async () => {
      try {
        const India = require('country-state-city').getStatesOfCountry('IN');
        const indiaStates = India.map((state) => state.name);
        setStates(indiaStates);
      } catch (error) {
        console.error('Error fetching states data:', error);
      }
    };

    if (selectedCountry === 'India') {
      fetchStates();
    } else {
      setStates([]);
    }

  }, [selectedCountry]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else {
      // Check if all fields are empty
      if (!selectedState && !pincode) {
        setFormError('All fields are empty');
        setValidated(true);
        return;
      }

      // Perform pin code validation
      if (selectedState && pincode) {
        validatePincode(selectedState, pincode);
      }
      setValidated(true);
    }
  };

  // discount coupen
  const [couponCode, setCouponCode] = useState('');

  const handleCouponChange = (e) => {
    setCouponCode(e.target.value);
  };

  const applyCoupon = () => {
    setCouponCode('');
  };

  const validatePincode = async (state, pincode) => {
    try {
      const response = await fetch(`https://api.postalpincode.in/pincode/${pincode}`);
      if (response.ok) {
        const data = await response.json();
        const pincodeState = data[0].PostOffice[0].State;
        if (pincodeState === state) {
          setPincodeError('');
        } else {
          setPincodeError('Invalid pin code for selected state');
        }
      } else {
        console.error('Pin code validation failed');
        setPincodeError('Failed to validate pin code');
      }
    } catch (error) {
      console.error('Error validating pin code:', error);
      setPincodeError('Failed to validate pin code');
    }
  };

  // cod
  const [pincodes, setPincodes] = useState('');
  const [codAvailable, setCodAvailable] = useState(null);
  const [error, setError] = useState('');

  const checkCOD = () => {
    setCodAvailable(null);
    setError('');

    if (!/^\d{6}$/.test(pincodes)) {
      setError('Please enter a valid 6-digit PIN code.');
      return;
    }

    if (parseInt(pincodes) % 2 === 0) {
      setCodAvailable(true);
    } else {
      setCodAvailable(false);
      setError('Cash on Delivery is not available for this pin code.');
    }
  };

  const deleteCartItem = async (cartId) => {
    try {
      const response = await fetch(`https://jinendra.online/api/deletecart/${cartId}`, {
        method: 'DELETE', // Use DELETE method for deleting data
      });
      if (!response.ok) {
        throw new Error(`Failed to delete cart item. Status: ${response.status} ${response.statusText}`);
      }
      const data = await response.json();
      setCartData(prevCartData => prevCartData.filter(item => item.cId !== cartId));

    } catch (error) {
    }
  }
  const [subtotal, setSubtotal] = useState(0); // State to hold subtotal
  useEffect(() => {
    calculateSubtotal();
  }, [cartData]);

  const calculateSubtotal = () => {
    let total = 0;
    cartData.forEach((item) => {
      total += item.discountedPrice * item.quantity;
    });
    setSubtotal(total);
  };

  const navigate = useNavigate();

  const handleCheckout = () => {
    if (cartData.length === 0) {
      alert("Your cart is empty. Please add items before proceeding to checkout.");
    } else {
      navigate('/checkout', { state: { cartData, subtotal } });
    }
  };

  return (
    <>
      <Header1 />
      <SocialMediaIcons />
      <div className='' style={{ backgroundColor: "#F5F3EA" }}>
        <div className="container mt-5 " >
          <h2>Shopping Cart</h2>
          <div className='row'>
            <div className='col-lg-8 bg-white p-3' >

              {cartData.length > 0 ? (
                <table>
                  <thead>
                    <th className='col-9'>Item</th>
                    <th className='col-1 text-center'>Price</th>
                    <th className='col-1 text-center'>Qty</th>
                    <th className='col-1 text-center'>Subtotal</th>

                  </thead>
                  <tbody>
                    {cartData.map((cartitems) => (
                      <tr key={cartitems.cId}>
                        <td className="col-9">
                          <span>
                            <img src={`https://jinendra.online/${cartitems.prodimg1}`} style={{ maxHeight: '135px', maxWidth: '135px' }} />
                            {cartitems.productName}
                          </span>
                        </td>
                        <td className="col-1 text-center">&#8377;{cartitems.discountedPrice}</td>
                        <td className="col-1 text-center">{cartitems.quantity}</td>
                        <td className="col-1 text-center">&#8377;{cartitems.discountedPrice * cartitems.quantity}</td>
                        <td><button onClick={() => deleteCartItem(cartitems.cId)} className='rounded-circle text-white border-0 ms-3' style={{ backgroundColor: "#1D1D1F" }}> <i className="fa-solid fa-x"></i></button></td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <p>Your cart is empty</p>
              )}

              <hr />
              <div className='d-flex flex-column flex-md-row justify-content-md-between '>
                <Link to="/">
                  <button className='rounded-5 p-2 fw-bold mb-2 mb-md-0 me-md-3' style={{ backgroundColor: "white", color: "#1D1D1F", border: "1px solid #1D1D1F", fontSize: "12px" }}>
                    <i className="fa-solid fa-less-than px-2" style={{ color: "#1D1D1F" }}></i>CONTINUE SHOPPING
                  </button>
                </Link>
                <div className='mb-2 mb-md-0'>
                </div>
              </div>

            </div>

            <div className='col-lg-4 p-3' style={{ backgroundColor: "#F9F9F9" }}>
              <h3>SUMMARY</h3>
              <p>You can earn<span style={{ color: "#1D1D1F" }}> 50 Reward Points</span>  for registration!</p>
              <div className='d-flex justify-content-between'>
                <div>
                  <ul className="list-unstyled mb-0">
                    <li>Subtotal</li>
                    <li>Tax</li>
                    <li>Order Total</li>
                  </ul>
                </div>
                <div>
                  <ul className="list-unstyled mb-0">
                    <li>&#8377;{subtotal}</li>
                    <li>0</li>
                    <li>&#8377;{subtotal}</li>
                  </ul>
                </div>
              </div>
              <button className='w-100 rounded-5 border-0 p-2 text-white fw-bold mt-3 mb-4' style={{ fontSize: "15px", backgroundColor: "#1D1D1F" }} onClick={handleCheckout} ><span className='co'>PROCESS TO CHECKOUT</span></button>
            </div>
          </div>
          <div className='p-3 w-100' style={{ backgroundColor: "#F9F9F9" }}>
            <Form.Group controlId="pincodes">
              <Form.Label>check for cash on delivery</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter pin code"
                value={pincodes}
                onChange={(e) => setPincodes(e.target.value)}
                className='w-25'
              />
            </Form.Group>
            <Button className='rounded-5 p-2 fw-bold mt-3' style={{ backgroundColor: "white", color: "#1D1D1F", border: "1px solid #1D1D1F", fontSize: "12px" }} onClick={checkCOD}>Check COD Availability</Button>
            {codAvailable === true && <Alert variant="success" className='w-50'>Cash on Delivery is available for this pin code.</Alert>}
            {codAvailable === false && <Alert variant="warning" className='w-50'>Cash on Delivery is not available for this pin code.</Alert>}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Cart;