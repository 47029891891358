import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './Components/Home';
import 'bootstrap/dist/css/bootstrap.min.css';
import ContactUs from './Components/ContactUs';
import Newcustomer from './Components/Newcustomer';
import ForgotPass from './Components/ForgotPass';
import CustomerLogin from './Components/CustomerLogin';
import Productpage from './Components/Productpage';
import AboutUs from './Components/AboutUs';
import ProductDetail from './Components/ProductDetail';
import Checkout from './Components/Checkout';
import Cart from './Components/Cart';
import MyAccount from './Components/CustomerPages/MyAccount';
import { useEffect, useState } from 'react';
import SubProductPage from './Components/SubProductPage';
import Myorder from './Components/CustomerPages/Myorder';
import CompareProduct from './Components/CompareProduct';
import Wishlist from './Components/Wishlist';
import CatlogPd from './Components/CatlogPd';
import AdvanceSearch from './Components/AdvanceSearch';

function App() {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch('https://jinendra.online/api/products_Productsdetails');
        if (!response.ok) {
          throw new Error('Failed to fetch products');
        }
        const data = await response.json();
        setProducts(data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching products:', error.message);
        setError(error.message);
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);


  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }
  return (

    <BrowserRouter>
      <Routes>
        <Route path='/' exact element={<Home />} />
        <Route path='/contactus' element={<ContactUs />} />
        <Route path='/newcustomer' element={<Newcustomer />} />
        <Route path='/forgotpass' element={<ForgotPass />} />
        <Route path='/customerlogin' element={<CustomerLogin />} />
        <Route path='/productpage/:categoryName' element={<Productpage />} />
        <Route path='/aboutus' element={<AboutUs />} />
        <Route path='/productdetails/:productId' element={<ProductDetail products={products} />} />
        <Route path='/checkout' element={<Checkout />} />
        <Route path='/cart' element={<Cart products={products} />} />
        <Route path="/productpage/:categoryName/:subcategory" element={<SubProductPage />} />
        <Route path="/myaccount" element={<MyAccount />} />
        <Route path='/myorders' element={<Myorder />} />
        <Route path='/compareproducts' element={<CompareProduct />} />
        <Route path="/wishlist" element={<Wishlist />} />
        <Route path="/catlogPd" element={<CatlogPd />} />
        <Route path="/advancesearch" element={<AdvanceSearch />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App;
