import React, { useState } from 'react'
import { Form, Row, Col } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import Header1 from './Header1';
import Footer from './Footer';
import { productAddedToCart } from './ProductDetail';
import SocialMediaIcons from './SocialMediaIcons';

function CustomerLogin() {
  const navigate = useNavigate();
  const countFromLocalStorage = localStorage.getItem('count');
  const count = countFromLocalStorage ? parseInt(countFromLocalStorage) : 1;
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    if (!phone || !password) {
      alert('Phone and password are required.');
      return;
    }

    try {
      const response = await fetch('https://jinendra.online/api/customer/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ phone, password }),
      });
 
      const data = await response.json();

      if (response.ok) {
        const userData = {
          phone: phone,
          userId: data.userId,
          firstname: data.firstname, // Assuming first name is available in data
        };

        localStorage.setItem('user', JSON.stringify(userData));
        // Check if there's a product to add to the cart
        const productToAdd = localStorage.getItem('productToAdd');
        if (productToAdd) {
          // Add the product to the cart
          await productAddedToCart(JSON.parse(productToAdd), count);
          localStorage.removeItem('productToAdd');
          localStorage.removeItem('count');
        }
        navigate('/');
      } else {
        alert('Invalid email or password. Please try again.');
      }
    } catch (error) {
      console.error('Error during login:', error);
    }
  };

  return (
    <>
      <Header1 />
      <SocialMediaIcons />
      <div className='pt-5 row justify-content-center' style={{ backgroundColor: "#F5F3EA", padding: "0 25px" }}>
        <h4 className='text-center mb-4'>CUSTOMER LOGIN</h4>
        <div className="p-3 col-lg-6 col-md-6 col-sm-12" style={{ backgroundColor: 'white', Width: '50%' }}>
          <p className='text-center fw-bold mb-0'>REGISTERED CUSTOMERS</p>
          <p className='text-center'>If you have an account, sign in with your Mobile Number.</p>
          <Row className="justify-content-center">
            <Col xs={12} sm={8} md={6} lg={6}>
              <Form className='p-3' onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="formGroupEmail">
                  <Form.Label>Mobile no.</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Mobile Number"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formGroupPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Form.Group>
                <button type='submit' className='rounded-5 w-100 border-0 text-white p-2 mt-2' style={{ backgroundColor: "#1D1D1F" }}>SIGN IN</button>
                <Link to="/forgotpass" style={{ marginLeft: "28%", color: "gray" }}>Forgot Your Password?</Link>
              </Form>
            </Col>
          </Row>
        </div>
      </div>
      <div className='row justify-content-center ' style={{ backgroundColor: "#F5F3EA", padding: "0 25px" }}>
        <div className="p-3 col-lg-6 col-md-6 col-sm-12 mb-3" style={{ backgroundColor: '#EEEEEE', Width: '50%' }}>
          <p className='text-center fw-bold mb-0'>NEW CUSTOMERS</p>
          <p className='w-50' style={{ fontSize: "12px", marginLeft: "25%" }}>Creating an account has many benefits: check out faster, keep more than one address, track orders and more.</p>
          <Link to='/newcustomer'><button className='rounded-5 w-50  border-0 text-white p-2 mt-2' style={{ backgroundColor: "#1D1D1F", marginLeft: "25%" }}>CREATE AN ACCOUNT</button></Link>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default CustomerLogin;