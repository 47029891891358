import React from 'react'
import '../Components/Footer.css'
import Accordion from 'react-bootstrap/Accordion';
import { Link } from 'react-router-dom';

function Footer() {
    return (
        <>
            <footer className='mt-5 d-none d-lg-block '>
                <div style={{ paddingLeft: '10%' }}>
                    <b>Be the first to save!</b>
                    <input type='email' placeholder='Enter your email address ' className='w-25 ms-2' style={{ border: '0.1px solid gray', borderRadius: '5px', height: '40px' }} />
                    <button type='submit' className='ms-1  border-0 rounded-2 px-4 text-white ' style={{ height: '40px', backgroundColor: '#1D1D1F', }}>Subscribe</button>
                </div>
                <div className='  d-flex justify-content-around mt-5'>
                    <div>
                        <img src='/images/jlogo.png' style={{ width: "250px" }} />
                        <ul className='mt-3' style={{ listStyle: 'none' }}>
                            <li>Got questions? Call us!</li>
                            <li>+91 12345678904</li>
                            <li>Mail us at<br />
                                customer@Jinendra.com</li>
                        </ul>

                    </div>
                    <div>
                        <div className='row '>
                            <div className='col-lg-3 ' >
                                <ul style={{ listStyle: 'none' }}>
                                    <b>Support</b>
                                    <Link to='/aboutus' style={{ textDecoration: "none", color: "black" }} ><li>About Us</li></Link>
                                    <li>Blog</li>
                                    <li>Contact Us</li>
                                    <li>Affiliate Program</li>
                                    <li>Humsafar Reward Program</li>
                                    <li>Our Stores</li>
                                    <li>Jinendra Humsafar Program</li>
                                    <li>Jinendra Device Protection</li>
                                </ul>
                            </div>
                            <div className='col-lg-3 p-0'>
                                <ul style={{ listStyle: 'none' }}>
                                    <b>Policies</b>

                                    <li>Privacy Policy</li>

                                    <li>Return & Refund Policy</li>
                                    <li>Shipping Policy</li>
                                    <li>Terms & Conditions</li>

                                </ul>
                            </div>
                            <div className='col-lg-3'>
                                <ul style={{ listStyle: 'none' }}>
                                    <b>Work with us</b>
                                    <li>Career</li>
                                    <li>Franchisee</li>


                                </ul>
                            </div>
                            <div className='col-lg-3'>
                                <ul style={{ listStyle: 'none' }}>
                                    <b>Connect with us</b>
                                    <ul className='d-flex justify-content-between p-0 mt-2' style={{ listStyle: 'none' }}>
                                        <li ><i className="fab fa-facebook-f fs-4"></i></li>
                                        <li ><i className="fab fa-twitter fs-4"></i></li>
                                        <li ><i className="fab fa-instagram fs-4"></i></li>
                                        <li><i className="fab fa-linkedin-in fs-4"></i></li>
                                    </ul>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <div className=' px-5 py-2 d-flex  d-none d-lg-flex justify-content-between' style={{ backgroundColor: "#F5F3EA" }}>
                <p >© 2024 Digihand India. All Rights Reserved.</p>
                <div className='d-flex   '>
                    <img src='/images/visa.png' width={50} height={30} className='shadow border me-2 ' />
                    <img src='/images/Amex.png' width={50} height={30} className='shadow border  me-2' />
                    <img src='/images/discover.png' width={50} height={30} className='shadow border  me-2' />
                    <img src='/images/paypal.png' width={50} height={30} className='shadow border  ' />
                </div>

            </div>
            {/* small devices */}
            <footer className=' d-lg-none  '>
                <div className='col'>
                    <div className='mb-3'>
                        <p className='row-6 text-center'><b>Be the first to save!</b></p>
                        <div className='row-6 text-center'>
                            <input type='email' placeholder='Enter your email address ' className='w-75 ms-2' style={{ border: '0.1px solid gray', borderRadius: '5px', height: '40px' }} />
                            <button type='submit' className='ms-1  border-0 rounded-2 px-4 text-white ' style={{ height: '40px', backgroundColor: '#1D1D1F', }}><i class="fa-brands fa-telegram fs-4" ></i></button>
                        </div>
                    </div>
                    <Accordion defaultActiveKey="" flush>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Support</Accordion.Header>
                            <Accordion.Body>
                                <ul style={{ listStyle: 'none' }}>

                                    <li>About Us</li>
                                    <li>Blog</li>
                                    <li>Contact Us</li>
                                    <li>Affiliate Program</li>
                                    <li>Humsafar Reward Program</li>
                                    <li>Our Stores</li>
                                    <li>Jinendra Humsafar Program</li>
                                    <li>Jinendra Device Protection</li>
                                </ul>

                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="1">
                            <Accordion.Header>Policy</Accordion.Header>
                            <Accordion.Body>
                                <ul style={{ listStyle: 'none' }}>
                                    <li>Privacy Policy</li>
                                    <li>Return & Refund Policy</li>
                                    <li>Shipping Policy</li>
                                    <li>Terms & Conditions</li>

                                </ul>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="2">
                            <Accordion.Header>Work With Us</Accordion.Header>
                            <Accordion.Body>
                                <ul style={{ listStyle: 'none' }}>
                                    <li>Career</li>
                                    <li>Franchisee</li>
                                </ul>
                            </Accordion.Body>
                        </Accordion.Item>


                        <Accordion.Item eventKey="3">
                            <Accordion.Header>Contact With Us</Accordion.Header>
                            <Accordion.Body>
                                <ul className='d-flex justify-content-between p-0 mt-2' style={{ listStyle: 'none' }}>
                                    <li ><i className="fab fa-facebook-f fs-4"></i></li>
                                    <li ><i className="fab fa-twitter fs-4"></i></li>
                                    <li ><i className="fab fa-instagram fs-4"></i></li>
                                    <li><i className="fab fa-linkedin-in fs-4"></i></li>
                                </ul>
                            </Accordion.Body>
                        </Accordion.Item>

                    </Accordion>

                    <div className='mt-5'>
                        <p className='text-center'>© 2024 Digihand India. All Rights Reserved.</p>
                        <div className='d-flex justify-content-evenly align-text-center mb-3'>
                            <img src='/images/Visa.png' width={50} height={30} className='shadow border' />
                            <img src='/images/Amex.png' width={50} height={30} className='shadow border' />
                            <img src='/images/discover.png' width={50} height={30} className='shadow border' />
                            <img src='/images/paypal.png' width={50} height={30} className='shadow border' />
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer;