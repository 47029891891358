import React, { useState, useEffect } from 'react';
import './SliderCarousel.css'; // Import the CSS file for styling
import PdHomes from './PdHomes';

const SliderCarousel = () => {
  const images = [
    '/images/carousel/cr-1.png',
    '/images/carousel/cr-2.png',
    '/images/carousel/cr-3.png',
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000); // Change slides every 3 seconds (adjust as needed)

    return () => clearInterval(interval);
  }, [currentIndex]); // Clear interval when component unmounts or index changes

  const handleChange = (index) => {
    setCurrentIndex(index);
  };

  return (
    <>
       <div className="slider-containerk container">
      <div className="sliderk">
        {images.map((imageUrl, index) => (
          <input
            key={index}
            type="radio"
            name="s"
          
            style={{ backgroundImage: `url(${imageUrl})` }}
            // title={`Image ${index + 1}`}
            checked={index === currentIndex}
            onChange={() => handleChange(index)}
          />
        ))}
        <button className="prev bk" onClick={() => handleChange((currentIndex - 1 + images.length) % images.length)}>
        &#10094;
        </button>
        <button className="next bk" onClick={() => handleChange((currentIndex + 1) % images.length)}>
        &#10095;
        </button>
      </div>
    </div>
    <PdHomes/>
    </>
 
  );
};

export default SliderCarousel;