import React, { useState } from "react";
import Header1 from "./Header1";
import Footer from "./Footer";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import SocialMediaIcons from "./SocialMediaIcons";

function Newcustomer() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    phone: "",
    email: "",
    password: "",
    confirmPassword: "",
    signUpNewsletter: false,
    allowShoppingAssistance: false,
  });
  const [formErrors, setFormErrors] = useState({});

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
    // Clear form error if the user types in the input field after an error message was shown
    if (formErrors[name]) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = {};
    if (!formData.firstname.trim()) {
      errors.firstname = "First name is required";
    }
   // if (!formData.lastname.trim()) {
   //   errors.lastname = "Last name is required";
   // }
    if (!formData.email.trim()) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Invalid email format";
    }
    if (!formData.password.trim()) {
      errors.password = "Password is required";
    } else if (formData.password.length < 6) {
      errors.password = "Password must be at least 6 characters long";
    }
    if (formData.password !== formData.confirmPassword) {
      errors.confirmPassword = "Passwords do not match";
    }
    // Update form errors state
    setFormErrors(errors);

    // If there are no errors, submit the form
    if (Object.keys(errors).length === 0) {
      try {
        // Send form data to backend to store in MongoDB
        const response = await axios.post(
          "https://jinendra.online/api/customer/register",
          formData
        );

        // Extract userId from the response
        const userId = response.data.userId;

        // Omitting password and confirmPassword from formData for localStorage
        const { password, confirmPassword, signUpNewsletter, allowShoppingAssistance, ...userData } = formData;
        // Add userId to userData
        userData.userId = userId;

        // Storing user data including userId in localStorage
        localStorage.setItem("userData", JSON.stringify(userData));

        // Reset form after successful submission
        setFormData({
          firstname: "",
          lastname: "",
          phone: "",
          email: "",
          password: "",
          confirmPassword: "",
          signUpNewsletter: false,
          allowShoppingAssistance: false,
        });

        alert("Registration successful: " + response.data.message);
        navigate("/customerlogin");
        // You can perform further actions after successful submission, such as redirecting the user
      } catch (error) {
        if (error.response && error.response.data) {
          alert(
            error.response.data.message ||
            "Error registering user. Please try again."
          );
        } else {
          alert("Error registering user. Please try again.");
        }
        console.error("Error submitting form data:", error);
        // You can handle errors, e.g., showing an error message to the user
      }
    }
  };
  return (
    <>
      <Header1 />
      <SocialMediaIcons />
      <div
        className="row justify-content-center"
        style={{ backgroundColor: "#F5F3EA", padding: "25px" }}
      >
        <h4 className="text-center mb-4">CREATE NEW CUSTOMER ACCOUNT</h4>
        <div
          className="p-3 col-lg-6 col-md-6 col-sm-12"
          style={{ backgroundColor: "white", Width: "50%" }}
        >
          <p className="text-center fw-bold">PERSONAL INFORMATION</p>
          <hr />
          <form onSubmit={handleSubmit}>
            <label htmlFor="firstname">Full Name</label>
            <br />
            <input
              type="text"
              className="w-100 mb-3 rounded"
              name="firstname"
              style={{ border: "1px solid lightGray", height: "35px" }}
              onChange={handleChange}
              value={formData.firstname}
            />
            {formErrors.firstname && (
              <div className="text-danger">{formErrors.firstname}</div>
            )}
            {/* <label htmlFor="lastname">Last Name</label>
            <br />
            <input
              type="text"
              className="w-100 rounded"
              name="lastname"
              style={{ border: "1px solid lightGray", height: "35px" }}
              onChange={handleChange}
              value={formData.lastname}
            />
            {formErrors.lastname && (
              <div className="text-danger">{formErrors.lastname}</div>
            )} */}

            <label className="mt-3" htmlFor="lastname">Mobile Number</label>
            <br />
            <input
              type="text"
              className="w-100 rounded "
              name="phone"
              style={{ border: "1px solid lightGray", height: "35px" }}
              onChange={handleChange}
              value={formData.phone}
            />
            {/* <div className="mt-3">
              <label className="mb-2">
                <input
                  type="checkbox"
                  name="signUpNewsletter"
                  onChange={handleChange}
                  checked={formData.signUpNewsletter}
                />
                Sign Up for Newsletter
              </label>
              <br />
              <label>
                <input
                  type="checkbox"
                  name="allowShoppingAssistance"
                  onChange={handleChange}
                  checked={formData.allowShoppingAssistance}
                />
                Allow remote shopping assistance
              </label>
            </div> */}
            <div className="mt-4 mb-3">
              <p className="text-center fw-bold">SIGN-IN INFORMATION</p>
              <hr />
              <input
                type="email"
                className="w-100 mb-3 rounded"
                name="email"
                placeholder="Enter email"
                style={{ border: "1px solid lightGray", height: "35px" }}
                onChange={handleChange}
                value={formData.email}
              />
              {formErrors.email && (
                <div className="text-danger">{formErrors.email}</div>
              )}
              <input
                type="password"
                className="w-100 mb-3 rounded"
                name="password"
                placeholder="Password"
                style={{ border: "1px solid lightGray", height: "35px" }}
                onChange={handleChange}
                value={formData.password}
              />
              {formErrors.password && (
                <div className="text-danger">{formErrors.password}</div>
              )}
              <input
                type="password"
                className="w-100 mb-3 rounded"
                name="confirmPassword"
                placeholder="Confirm Password"
                style={{ border: "1px solid lightGray", height: "35px" }}
                onChange={handleChange}
                value={formData.confirmPassword}
              />
              {formErrors.confirmPassword && (
                <div className="text-danger">{formErrors.confirmPassword}</div>
              )}
              <button
                type="submit"
                className="rounded-5 w-100 border-0 text-white p-2 mt-2"
                style={{ backgroundColor: "#1D1D1F" }}
              >
                CREATE AN ACCOUNT
              </button>
              <Link to="/customerlogin">
                <button
                  className="rounded-5 w-100 border-0 text-white p-2 mt-2"
                  style={{ backgroundColor: "#1D1D1F" }}
                >
                  BACK
                </button>
              </Link>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Newcustomer;
