// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.arrow-and-hurt:hover {
  color: #ff6600;
}

/* popup content css end  */


.product-image-container {
  overflow: hidden;
  /* Hide overflow to prevent zoomed image from overflowing */
}

.product-image {
  transition: transform 0.3s ease;
  /* Smooth transition for zoom effect */
  max-width: 100%;
  /* Ensure the image fits within its container */
  max-height: 100%;
  /* Ensure the image fits within its container */
}

.buy-btns button i:hover {
  transform: translateX(10px);
  /* Adjust the value as per your preference */
}

.magnify img {
  max-width: 100%;
  display: block;
}

.magnify-large {
  position: absolute;
  display: none;
  width: 200px;
  /* Adjust the width and height of the magnifying glass */
  height: 200px;
  border-radius: 50%;
  border: 2px;
  background: white;
  box-shadow: 0 0 0 7px rgba(0, 0, 0, 0.1);
  cursor: none;
}`, "",{"version":3,"sources":["webpack://./src/Components/ProductDetailPopup.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA,2BAA2B;;;AAG3B;EACE,gBAAgB;EAChB,2DAA2D;AAC7D;;AAEA;EACE,+BAA+B;EAC/B,sCAAsC;EACtC,eAAe;EACf,+CAA+C;EAC/C,gBAAgB;EAChB,+CAA+C;AACjD;;AAEA;EACE,2BAA2B;EAC3B,4CAA4C;AAC9C;;AAEA;EACE,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,YAAY;EACZ,wDAAwD;EACxD,aAAa;EACb,kBAAkB;EAClB,WAAW;EACX,iBAAiB;EACjB,wCAAwC;EACxC,YAAY;AACd","sourcesContent":[".arrow-and-hurt:hover {\n  color: #ff6600;\n}\n\n/* popup content css end  */\n\n\n.product-image-container {\n  overflow: hidden;\n  /* Hide overflow to prevent zoomed image from overflowing */\n}\n\n.product-image {\n  transition: transform 0.3s ease;\n  /* Smooth transition for zoom effect */\n  max-width: 100%;\n  /* Ensure the image fits within its container */\n  max-height: 100%;\n  /* Ensure the image fits within its container */\n}\n\n.buy-btns button i:hover {\n  transform: translateX(10px);\n  /* Adjust the value as per your preference */\n}\n\n.magnify img {\n  max-width: 100%;\n  display: block;\n}\n\n.magnify-large {\n  position: absolute;\n  display: none;\n  width: 200px;\n  /* Adjust the width and height of the magnifying glass */\n  height: 200px;\n  border-radius: 50%;\n  border: 2px;\n  background: white;\n  box-shadow: 0 0 0 7px rgba(0, 0, 0, 0.1);\n  cursor: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
