import React, { useEffect, useState } from 'react';
import Header1 from '../Header1';
import Footer from '../Footer';

function Myorder() {
  const [userOrders, setUserOrders] = useState(null);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchUserOrders = async () => {
      try {
        const authString = localStorage.getItem("user");
        const auth = JSON.parse(authString);

        if (!auth || !auth.userId) {
          throw new Error("User ID is not available");
        }

        const response = await fetch("https://jinendra.online/api/getuserorders", {
          headers: {
            userid: auth.userId // Pass the logged-in user's ID in the request headers
          }
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        if (data && data.data) {
          // Sort the user orders based on order date in descending order
          const sortedOrders = data.data.sort((a, b) => new Date(b.dateTime) - new Date(a.dateTime));
          setUserOrders(sortedOrders);
        }

      } catch (error) {
        console.error("Error fetching user orders:", error);
      }
    };
    const fetchData = async () => {
      try {
        const response = await fetch('https://jinendra.online/api/products_allProducts_myorder');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setProducts(data); // assuming the response is an array of products
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchUserOrders();
    fetchData();
  }, []);

  return (
    <>
      <Header1 />
      <div>
        <div className='py-5' style={{ backgroundColor: "#F5F3EA" }}>
          <div className="container">
            <div className="border bg-white flex-1" style={{ padding: "20px" }}>
              <div>
                <h3>Orders</h3>
                <hr />
                {userOrders && (
                  <div>
                    {userOrders.map((order, index) => (
                      <div key={index}>
                        {/* <h4>Order {index + 1}</h4> */}
                        <div style={{ listStyle: "none" }}>
                          {/* <p>Order ID: {order.orderId}</p> */}
                          <p>Name:{order.firstName}&nbsp;{order.lastName}</p>
                          <p>Address: {order.address},&nbsp;{order.city},&nbsp;{order.state},&nbsp;{order.postalCode}</p>
                          <p>Order Date: {order.dateTime}</p>
                          <p>Total Amount: &#8377; {order.amount}</p>
                          {/* <p>Product Id: {order.productId}</p> */}
                          <h3> Order Products</h3>
                          <ul style={{ listStyle: "none" }}>
                            {order.productId && order.productId.split(',').map(productId => {
                              const product = products.find(product => product.productId === productId.trim());
                              if (product) {
                                return (
                                  <li key={product.productId}>
                                    <img src={`https://jinendra.online/${product.prodimg1}`} alt={product.productName} width={80} height={80} /><br />
                                    { }
                                    {product.productName}&nbsp;
                                    &#8377;{product.discountedPrice}
                                  </li>
                                );
                              }
                              return null;
                            })}
                          </ul>
                        </div>
                        <hr />
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Myorder;