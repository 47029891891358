import { Link, useNavigate, useParams } from 'react-router-dom'
import Accordion from 'react-bootstrap/Accordion';
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import './ProductDetails.css'
import Header1 from './Header1';
import Footer from './Footer';
import Review from './Review';
import { Form, Button, Alert } from 'react-bootstrap';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import axios from 'axios'
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import SocialMediaIcons from './SocialMediaIcons';

export const productAddedToCart = async (productData, count) => {

  try {
    const auth = localStorage.getItem('user');
    const userId = auth ? JSON.parse(auth).userId : null;

    if (!userId) {
      localStorage.setItem('productToAdd', JSON.stringify(productData));
      window.location.href = '/customerlogin';
      return;
    }

    // Include userId in the productData object
    productData.userId = userId;

    const response = await axios.post('https://jinendra.online/api/addProductToCart', { ...productData, quantity: count });

    if (response.status === 201) {
      // Redirect the user to the cart page
      window.location.href = '/cart';
    } else {
      console.error('Failed to add product to cart:', response.data.message);
      // Handle the error
    }
  } catch (error) {
    console.error('Error adding product to cart:', error);
    // Handle network errors or other exceptions
  }
};

export const handleAddToCompare = (product, product1, { images }) => {
  const storedProducts = localStorage.getItem('compare_products');
  let comparedProducts = [];

  if (storedProducts) {
    comparedProducts = JSON.parse(storedProducts);
  }

  const isProductAlreadyCompared = comparedProducts.some(item => item.basicInfo.productId === product.productId);

  if (!isProductAlreadyCompared) {
    comparedProducts.push({
      basicInfo: product,
      moreInfo: product1 // Here we assign Product1 data to moreInfo
    });

    localStorage.setItem('compare_products', JSON.stringify(comparedProducts));

    const comparePopup = document.getElementById('compare-popup');
    if (comparePopup) {
      comparePopup.style.display = 'block';
      setTimeout(() => {
        comparePopup.style.display = 'none';
      }, 3000);
    }
  } else {
    alert('Product is already added to compare.');
  }
};

const ProductDetail = ({ products }) => {
  let { productId } = useParams();
  productId = parseInt(productId);

  const product = products.data.find(product => product.productId === productId);
  const product1 = products.data1.find(product => product.productId === productId);
  const product2 = products.data2.find(product => product.productId === productId);

  const images = [
    product.prodimg1,
    product.prodimg2,
    product.prodimg3,
    product.prodimg4,
    product.prodimg5,
    // Add more image URLs as needed
  ];

  const [productData, setProductData] = useState(null);
  const [mainImage, setMainImage] = useState('');
  useEffect(() => {
    const foundProduct = products.data.find(product => product.productId === productId);
    if (foundProduct) {
      setProductData(foundProduct);
      setMainImage(foundProduct.prodimg1); // Set the main image when product is found
    }
  }, [productId, products.data]);

  const handleImageClick = (image) => {
    setMainImage(image);
  };

  const handleAddToCart = () => {
    // Pass product data, count, and selected country to productAddedToCart function
    productAddedToCart(product, count);


  };

  const [count, setCount] = useState(1); // Set initial count to 1

  const increment = () => {
    setCount(prevCount => prevCount + 1);
  };

  const decrement = () => {
    // Check if count is greater than 1 before decrementing
    if (count > 1) {
      setCount(prevCount => prevCount - 1);
    }
  };
  useEffect(() => {
    localStorage.setItem('count', count.toString());
  }, [count]);

  // couting for products increament end 
  //shipping
  const [selectedCountry, setSelectedCountry] = useState('India');
  const [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState('');
  const [pincode, setPincode] = useState('');
  const [pincodeError, setPincodeError] = useState('');
  const [validated, setValidated] = useState(false);
  const [formError, setFormError] = useState('');
  useEffect(() => {
    // Fetch states for the selected country
    const fetchStates = async () => {
      try {
        const India = require('country-state-city').getStatesOfCountry('IN');
        const indiaStates = India.map((state) => state.name);
        setStates(indiaStates);
      } catch (error) {
        console.error('Error fetching states data:', error);
      }
    };

    if (selectedCountry === 'India') {
      fetchStates();
    } else {
      setStates([]);
    }
  }, [selectedCountry]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else {
      // Check if all fields are empty
      if (!selectedState && !pincode) {
        setFormError('All fields are empty');
        setValidated(true);
        return;
      }

      // Perform pin code validation
      if (selectedState && pincode) {
        validatePincode(selectedState, pincode);
      }
      setValidated(true);
    }
  };

  // discount coupen
  const [couponCode, setCouponCode] = useState('');

  const handleCouponChange = (e) => {
    setCouponCode(e.target.value);
  };

  const applyCoupon = () => {
    // Implement coupon validation or application logic here
    // console.log('Coupon code:', couponCode);
    // Reset coupon code input after applying coupon
    setCouponCode('');
  };

  const validatePincode = async (state, pincode) => {
    // Pin code validation logic
    try {
      const response = await fetch(`https://api.postalpincode.in/pincode/${pincode}`);
      if (response.ok) {
        const data = await response.json();
        const pincodeState = data[0].PostOffice[0].State;
        if (pincodeState === state) {
          setPincodeError('');
        } else {
          setPincodeError('Invalid pin code for selected state');
        }
      } else {
        console.error('Pin code validation failed');
        setPincodeError('Failed to validate pin code');
      }
    } catch (error) {
      console.error('Error validating pin code:', error);
      setPincodeError('Failed to validate pin code');
    }
  };

  const addToWishlist = async (product) => {
    // Check if the user is logged in
    const auth = localStorage.getItem('user');
    const userId = auth ? JSON.parse(auth).userId : null;

    if (!userId) {
      alert('Please login to add products to wishlist');
      return;
    }

    try {
      // Make the POST request to add the product to the wishlist
      const response = await axios.post('https://jinendra.online/api/addwishlist', {
        ...product,
        userId: userId // Include the user ID in the product data
      });

      if (response.status === 201) {

        // console.log('Product added to wishlist successfully');
      } else {
        console.error('Failed to add product to wishlist:', response.data.message);
        // Handle the error
      }

      alert("Product add to wishlist")
    } catch (error) {
      console.error('Error adding product to wishlist:', error);
      // Handle network errors or other exceptions
    }
  };

  const [isHovered, setIsHovered] = useState(false);
  document.querySelectorAll('.magnify-container').forEach(container => {
    const magnify = container.querySelector('.magnify');
    const magnifyImg = container.querySelector('.magnify img');
    const magnifyLarge = container.querySelector('.magnify-large');

    const zoomLevel = 3; // Adjust the zoom level as needed

    magnify.addEventListener('mousemove', function (e) {
      const { left, top } = magnify.getBoundingClientRect();
      const x = e.clientX - left;
      const y = e.clientY - top;

      const magnifyWidth = magnify.offsetWidth;
      const magnifyHeight = magnify.offsetHeight;
      const magnifyLargeWidth = magnifyLarge.offsetWidth;
      const magnifyLargeHeight = magnifyLarge.offsetHeight;

      // Calculate the position of the magnifying glass
      let posX = x - magnifyLargeWidth / 2;
      let posY = y - magnifyLargeHeight / 2;

      // Ensure the magnifying glass stays within the bounds of its container
      posX = Math.max(0, Math.min(posX, magnifyWidth - magnifyLargeWidth));
      posY = Math.max(0, Math.min(posY, magnifyHeight - magnifyLargeHeight));

      // Calculate the zoomed image position
      const bgPosX = -((x / magnifyWidth) * (magnifyImg.offsetWidth * zoomLevel - magnifyWidth));
      const bgPosY = -((y / magnifyHeight) * (magnifyImg.offsetHeight * zoomLevel - magnifyHeight));

      // Apply the zoom effect
      magnifyLarge.style.backgroundImage = `url('${magnifyImg.src}')`;
      magnifyLarge.style.backgroundSize = `${magnifyImg.offsetWidth * zoomLevel}px ${magnifyImg.offsetHeight * zoomLevel}px`;
      magnifyLarge.style.backgroundPosition = `${bgPosX}px ${bgPosY}px`;

      // Show the magnifying glass
      magnifyLarge.style.display = 'block';
      magnifyLarge.style.left = `${posX}px`;
      magnifyLarge.style.top = `${posY}px`;
    });

    magnify.addEventListener('mouseleave', function () {
      magnifyLarge.style.display = 'none';
    });
  });

  const [currentIndex, setCurrentIndex] = useState(0);

  const [slideDirection, setSlideDirection] = useState(null);

  const handlePrev = () => {
    setSlideDirection('right'); // Set direction for animation
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
    setTimeout(() => setSlideDirection(null), 500); // Reset slide direction after animation
  };

  const handleNext = () => {
    setSlideDirection('left'); // Set direction for animation
    setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
    setTimeout(() => setSlideDirection(null), 500); // Reset slide direction after animation
  };

  return (
    <>
      <Header1 />
      <SocialMediaIcons />
      <div className='detail-page-main-section'>
        <section className="p-2">
          <div className="container main-container-detail-page">
            <Breadcrumb >
              <Breadcrumb.Item style={{ textDecoration: "none" }}><Link to="/" style={{ color: "gray" }} >Home</Link></Breadcrumb.Item>
              <Breadcrumb.Item active>{product.catgoryName}</Breadcrumb.Item>
              <Breadcrumb.Item active>{product.SubCatName}</Breadcrumb.Item>
              <Breadcrumb.Item active>{product.productName}</Breadcrumb.Item>
            </Breadcrumb>
            <div className="row gx-5">
              <aside className="col-lg-4 first-and-second-div">
                <div className="mb-5 d-flex justify-content-center align-items-center magnify-container position-relative">
                  <div className="slider-arrow left-arrow" onClick={handlePrev}>
                    &lt; {/* Replace with your left arrow icon */}
                  </div>
                  <div className={`magnify ${slideDirection}`}>
                    <div className="magnify-large"></div>
                    <img
                      className="rounded-4 fit"
                      src={images[currentIndex]}
                      alt="Image"
                      id="magnify-img"
                    />
                  </div>
                  <div className="slider-arrow right-arrow" onClick={handleNext}>
                    &gt; {/* Replace with your right arrow icon */}
                  </div>
                </div>

                <div className="d-flex justify-content-center mb-3">
                  {images &&
                    images.map((image, index) => (
                      <img
                        width="60"
                        height="60"
                        className="rounded-2"
                        key={index}
                        src={image}
                        alt={`Thumbnail ${index}`}
                        style={{ width: '100px', margin: '0 10px', cursor: 'pointer' }}
                        onClick={() => setCurrentIndex(index)}
                      />
                    ))}
                </div>
              </aside>


              <main className="col-lg-5 first-and-second-div">
                <div className="ps-lg-3">
                  <h3 className="title text-dark">
                    {/* Apple iPhone 15 Plus (Black, 128 GB) */}
                    {/* {product.productName} */}
                    {product.productName}

                  </h3>
                  <p><Link className='short-title'><b>BE THE FIRST TO REVIEW THIS PRODUCT</b></Link></p>
                  <div className="d-flex flex-row my-3">
                    <span className="text-success  " style={{ fontSize: "12px" }}><strong>IN STOCK</strong></span>
                  </div>
                  <div>
                    <p style={{ fontSize: "13px", color: "gray" }}  ><b>SKU</b> <span>1234567890</span></p>
                    <span data-bind="css: frontend_class" className="amasty-rewards-highlight"></span> <span><b>You can earn <strong className='earn-title'>50 Reward Points </strong> for registration!</b></span>

                  </div>

                  <div>
                    <p>Check For Cash On Delivery</p>
                    <input type='text' className='w-75 mb-3 rounded-1' style={{ border: "1px solid gray ", height: "35px", color: "gray" }} required placeholder='Enter Pincode To Check COD' /><button type="button" className="btn rounded-5 fw-bold  mb-2" style={{ color: "#1D1D1F", border: "1px solid #1D1D1F ", fontSize: "10px", width: "15%", padding: "10px" }}>CHECK</button>
                  </div>

                  <button type="button" className="btn rounded-5 mt-3" style={{ backgroundColor: '#1D1D1F', color: 'white' }}>CASHBACK & EMI</button>

                  <div className='mt-3 '>
                    <Accordion  >
                      <Accordion.Item eventKey="0">
                        <Accordion.Header className='m-0'>
                          <div className="row d-flex justify-center-between">
                            <div className='col-lg-6'>
                              <img src='../images/truck-icon.png' className='me-2' />
                              <span>SHIP TO <span ><img src='../images/flag.png' /></span></span>
                            </div>
                            <div className='col-lg-6'>
                              <p className='calculate-header'>Calculate Shipping Cost</p>
                            </div>
                          </div>
                        </Accordion.Header>

                        <Accordion.Body>
                          <p>Enter your destination to get a shipping estimate.</p>
                          <Form noValidate validated={validated} onSubmit={handleSubmit}>
                            <Form.Group controlId="country">
                              <Form.Label>Country</Form.Label>
                              <CountryDropdown
                                className="form-control"
                                value={selectedCountry}
                                onChange={(val) => setSelectedCountry(val)}
                                disabled={true} // Since we are only allowing India
                                required
                              />
                              <Form.Control.Feedback type="invalid">
                                Please select a country.
                              </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId="state">
                              <Form.Label>State</Form.Label>
                              <RegionDropdown
                                className="form-control"
                                country="India"
                                value={selectedState}
                                onChange={(val) => setSelectedState(val)}
                                blankOptionLabel="Select State"
                                required
                              />
                              <Form.Control.Feedback type="invalid">
                                Please select a state.
                              </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId="pincode">
                              <Form.Label>Pin Code</Form.Label>
                              <Form.Control
                                type="text"
                                value={pincode}
                                onChange={(e) => setPincode(e.target.value)}
                                required
                              />
                              <Form.Control.Feedback type="invalid">
                                Please provide a valid pin code.
                              </Form.Control.Feedback>
                              {pincodeError && <Form.Text className="text-danger">{pincodeError}</Form.Text>}
                            </Form.Group>
                            {formError && <div className="text-danger">{formError}</div>}
                            <Button className='mt-3 rounded-5 text-dark' style={{ fontSize: "12px", backgroundColor: "#E2E2E2" }} type="submit">CHECK</Button>
                          </Form>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>

                  <div className="product attribute overview mt-4 mb-4" style={{ lineHeight: '1' }}>
                    <strong className="type">Quick Overview</strong>
                    <div className="value mt-3" itemprop="description" style={{ lineHeight: '1' }}>
                      <ul style={{ lineHeight: '1' }}>
                        <li style={{ lineHeight: '1' }}>128 GB ROM</li>
                        <li style={{ lineHeight: '1' }}>17.02 cm (6.7 inch) Super Retina XDR Display</li>
                        <li style={{ lineHeight: '1' }}>48MP + 12MP | 12MP Front Camera</li>
                        <li style={{ lineHeight: '1' }}>A16 Bionic Chip, 6 Core Processor Processor</li>
                      </ul>
                    </div>
                  </div>

                </div>
              </main>
              <div className='col-lg-3 card-description first-and-second-div'>
                <div className='old-new-price mb-3 d-flex'>
                  <h3 className='new-price-cart'><b>₹{product.discountedPrice}</b></h3> <span className="fs-4 ms-2" style={{ color: "gray" }}><del>₹{product.orgPrice}</del></span>
                </div>


                <div className="col-md-4 col-6 mb-3">
                  <label className="mb-2 d-block">QAT</label>
                  <div className="input-group mb-3" style={{ width: '170px' }}>
                    <button onClick={decrement} className="btn btn-white px-3 " type="button" id="button-addon1" data-mdb-ripple-color="dark">
                      <i className="fas fa-minus" style={{ color: "gray" }}></i>
                    </button>
                    <strong className='mx-4 mt-2'>{count}</strong>
                    <button onClick={increment} className="btn btn-white px-3" type="button" id="button-addon2" data-mdb-ripple-color="dark">
                      <i className="fas fa-plus" style={{ color: "gray" }}></i>
                    </button>
                  </div>
                </div>
                <hr />
                <div className='buy-btns'>
                  <div className="d-grid gap-2">
                    <Link to={`/cart`} className="d-block text-center">
                      <button className="btn btn-primary buy-btns " type="button" style={{ backgroundColor: '#1D1D1F', color: 'white', width: '100%' }} onClick={() => handleAddToCart(product, count)}><span className='co'>ADD TO CART</span></button>
                    </Link>
                    <Link to='/cart' className="d-block text-center">
                      <button className="btn btn-primary buy-btns" type="button" style={{ backgroundColor: '#1D1D1F', color: 'white', width: '100%' }} onClick={() => handleAddToCart(product, count)}><span className='co' >BUY NOW</span></button>
                    </Link>
                  </div>
                </div>

                {/* Popup for added to compare */}
                <div id="compare-popup" style={{ display: 'none' }}>
                  <div className="compare-popup" style={{
                    border: '2px solid black',
                    borderRadius: '8px',
                    padding: '10px',
                    backgroundColor: 'white',
                    color: 'green',
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: "5px"
                  }}>
                    <FontAwesomeIcon icon={faCheckCircle} style={{ marginRight: '8px' }} />
                    <span>Product added to compare!</span>
                  </div>
                </div>

                <div className='bottom-of-buy-btn-productDetail mt-4 mb-1 row'>
                  <div className='col-6 arrow-and-hurt ' style={{ cursor: 'pointer' }} onClick={() => { addToWishlist(product) }}>
                    <i className="fa-solid fa-heart"></i><span > ADD TO WISHLIST </span>
                  </div>
                  <div className='col-6 arrow-and-hurt' style={{ cursor: 'pointer' }} onClick={() => handleAddToCompare(product, product1)}>
                    <i className="fa-solid fa-arrow-right-arrow-left"></i><span> ADD TO COMPARE</span>
                  </div>

                </div>

                <div className='social-media-icon-cart-page mt-4'>
                  <i className="fa-brands fa-facebook-f"></i>
                  <i className="fa-brands fa-twitter"></i>
                  <i className="fa-brands fa-linkedin-in"></i>
                  <i className="fa-brands fa-pinterest"></i>
                  <i className="fa-brands fa-square-whatsapp"></i>
                </div>

              </div>
            </div>
          </div>
        </section>
       
        <section className='container more-about mb-5 card-more-detail'>
          <Tabs defaultActiveKey="Details" id="justify-tab-example" className="mb-3 row" justify>
            <Tab eventKey="Details" title="DETAILS" className='tab-btns col-sm-4 col-md-4'>
              <p>{product2.link}</p>
            </Tab>

            <Tab eventKey="MORE INFORMATION" title="MORE INFORMATION" className='tab-btns col-sm-4 col-md-4'>
              <div className="table-responsive">
                <table className="table border">
                  <tbody>
                    <tr>
                      <th style={{ borderRight: "1px solid #ccc" }}>Variant</th>
                      <td>{product1.varient}</td>
                    </tr>
                    {/* Add other rows as needed */}
                    <tr>
                      <th style={{ borderRight: "1px solid #ccc" }}>Color</th>
                      <td>{product1.color}</td>
                    </tr>
                    <tr>
                      <th style={{ borderRight: "1px solid #ccc" }}>Brand</th>
                      <td>{product1.Brand}</td>
                    </tr>
                    <tr>
                      <th style={{ borderRight: "1px solid #ccc" }}>OS</th>
                      <td>{product1.OS}</td>
                    </tr>
                    <tr>
                      <th style={{ borderRight: "1px solid #ccc" }}>RAM</th>
                      <td>{product1.RAM}</td>
                    </tr>
                    <tr>
                      <th style={{ borderRight: "1px solid #ccc" }}>Processor Core</th>
                      <td>{product1.ProcessorCore}</td>
                    </tr>
                    <tr>
                      <th style={{ borderRight: "1px solid #ccc" }}>Battery</th>
                      <td>{product1.Battery}</td>
                    </tr>
                    <tr>
                      <th style={{ borderRight: "1px solid #ccc" }}>Display</th>
                      <td>{product1.Display}</td>
                    </tr>
                    <tr>
                      <th style={{ borderRight: "1px solid #ccc" }}>Fingerprint</th>
                      <td>{product1.Fingerprint}</td>
                    </tr>
                    <tr>
                      <th style={{ borderRight: "1px solid #ccc" }}>Internal Memory</th>
                      <td>{product1.Internal_Memory}</td>
                    </tr>
                    <tr>
                      <th style={{ borderRight: "1px solid #ccc" }}>Connectivity</th>
                      <td>{product1.Connectivity}</td>
                    </tr>
                    <tr>
                      <th style={{ borderRight: "1px solid #ccc" }}>Sim Type</th>
                      <td>{product1.SimType}</td>
                    </tr>
                    <tr>
                      <th style={{ borderRight: "1px solid #ccc" }}>Primary Camera</th>
                      <td>{product1.PrimaryCamera}</td>
                    </tr>
                    <tr>
                      <th style={{ borderRight: "1px solid #ccc" }}>Secondary Camera</th>
                      <td>{product1.SecondaryCamera}</td>
                    </tr>
                    <tr>
                      <th style={{ borderRight: "1px solid #ccc" }}>Features</th>
                      <td>{product1.Features}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Tab>

            <Tab eventKey="REVIEWS" title="REVIEWS" className='tab-btns col-sm-3 w-100 col-md-4'>
              <Review />
            </Tab>
          </Tabs>
        </section>
      </div>
      <Footer />
    </>
  )
}

export default ProductDetail;