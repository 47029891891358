import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import './ProductDetailPopup.css';
import { FaEye } from 'react-icons/fa';
import { handleAddToCompare } from './ProductDetail'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { productAddedToCart } from './ProductDetail';
import axios from 'axios';

const ProductDetailPopup = ({ products, productId }) => {
  const [product, setProduct] = useState(null);
  const [count, setCount] = useState(1);
  const [pincodes, setPincodes] = useState('');
  const [codAvailable, setCodAvailable] = useState(null);
  const [error, setError] = useState('');

  useEffect(() => {
    if (productId && products) {
      const foundProduct = products.find(product => product.productId === productId);
      setProduct(foundProduct);
    }
  }, [productId, products]);

  const [currentImage, setCurrentImage] = useState(null);
  useEffect(() => {
    if (product && product.prodimg1) {
      setCurrentImage(product.prodimg1);
    }  }, [product]);
  const increment = () => {
    setCount(prevCount => prevCount + 1);
  };

  const decrement = () => {
    if (count > 1) {
      setCount(prevCount => prevCount - 1);
    }
  };
  if (!product) {
    return <div>Loading...</div>;
  }

  document.querySelectorAll('.magnify-container').forEach(container => {
    const magnify = container.querySelector('.magnify');
    const magnifyImg = container.querySelector('.magnify img');
    const magnifyLarge = container.querySelector('.magnify-large');

    const zoomLevel = 3; // Adjust the zoom level as needed

    magnify.addEventListener('mousemove', function (e) {
      const { left, top } = magnify.getBoundingClientRect();
      const x = e.clientX - left;
      const y = e.clientY - top;

      const magnifyWidth = magnify.offsetWidth;
      const magnifyHeight = magnify.offsetHeight;
      const magnifyLargeWidth = magnifyLarge.offsetWidth;
      const magnifyLargeHeight = magnifyLarge.offsetHeight;

      // Calculate the position of the magnifying glass
      let posX = x - magnifyLargeWidth / 2;
      let posY = y - magnifyLargeHeight / 2;

      // Ensure the magnifying glass stays within the bounds of its container
      posX = Math.max(0, Math.min(posX, magnifyWidth - magnifyLargeWidth));
      posY = Math.max(0, Math.min(posY, magnifyHeight - magnifyLargeHeight));

      // Calculate the zoomed image position
      const bgPosX = -((x / magnifyWidth) * (magnifyImg.offsetWidth * zoomLevel - magnifyWidth));
      const bgPosY = -((y / magnifyHeight) * (magnifyImg.offsetHeight * zoomLevel - magnifyHeight));

      // Apply the zoom effect
      magnifyLarge.style.backgroundImage = `url('${magnifyImg.src}')`;
      magnifyLarge.style.backgroundSize = `${magnifyImg.offsetWidth * zoomLevel}px ${magnifyImg.offsetHeight * zoomLevel}px`;
      magnifyLarge.style.backgroundPosition = `${bgPosX}px ${bgPosY}px`;

      // Show the magnifying glass
      magnifyLarge.style.display = 'block';
      magnifyLarge.style.left = `${posX}px`;
      magnifyLarge.style.top = `${posY}px`;
    });

    magnify.addEventListener('mouseleave', function () {
      magnifyLarge.style.display = 'none';
    });
  });

  const addToWishlist = async (product) => {
    // Check if the user is logged in
    const auth = localStorage.getItem('user');
    const userId = auth ? JSON.parse(auth).userId : null;

    if (!userId) {
      alert('Please login to add products to wishlist');
      return;
    }

    try {
      // Make the POST request to add the product to the wishlist
      const response = await axios.post('https://jinendra.online/api/addwishlist', {
        ...product,
        userId: userId // Include the user ID in the product data
      });

      if (response.status === 201) {

        // console.log('Product added to wishlist successfully');
      } else {
        console.error('Failed to add product to wishlist:', response.data.message);
        // Handle the error
      }

      alert("Product add to wishlist")
    } catch (error) {
      console.error('Error adding product to wishlist:', error);
      // Handle network errors or other exceptions
    }
  };

  const checkCOD = () => {
    // Clear previous state
    setCodAvailable(null);
    setError('');

    // Validate pincode format
    if (!/^\d{6}$/.test(pincodes)) {
      setError('Please enter a valid 6-digit PIN code.');
      return;
    }

    // Check COD availability
    if (parseInt(pincodes) % 2 === 0) {
      setCodAvailable(true);
    } else {
      setCodAvailable(false);
      setError('Cash on Delivery is not available for this pin code.');
    }
  };

  return (
    <div>
      {/* Your JSX content using the 'product' state */}
      <div>
        <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target={`#staticBackdrop${productId}`}>
          <FaEye className="eye-icon fs-2" />
        </button>

        <div className="modal fade" id={`staticBackdrop${productId}`} data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby={`staticBackdropLabel${productId}`} aria-hidden="true">
          <div className="modal-dialog modal-xl modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <div className="container">
                  <div className="row">
                    <div className="col-md-6">
                      <aside className="col-lg-12 first-and-second-div">
                        <div className="mb-3 d-flex justify-content-center magnify-container">
                          <Link to="#" className="rounded-4" data-type="image">
                            <div className="magnify" id="magnify">
                              <div className="magnify-large" id="magnify-large"></div>
                              <img
                                style={{ maxWidth: '100%', maxHeight: '100vh', margin: 'auto' }}
                                className="rounded-4 fit"
                                src={`https://jinendra.online/${currentImage}`}
                                alt="Main Product"
                              />
                            </div>
                          </Link>
                        </div>
                        <div className="d-flex justify-content-center mb-3">
                          {[product.prodimg1, product.prodimg2, product.prodimg3, product.prodimg4, product.prodimg5].map((img, index) => (
                            <Link
                              to="#"
                              key={index}
                              className="border mx-1 rounded-2 item-thumb"
                              data-type="image"
                              onClick={() => setCurrentImage(img)}
                            >
                              <img
                                width="60"
                                height="60"
                                className="rounded-2"
                                src={`https://jinendra.online/${img}`}
                                alt={`Thumbnail ${index + 1}`}
                              />
                            </Link>
                          ))}
                        </div>
                      </aside>
                    </div>
                    <div className="col-md-6">
                      <div className="ps-lg-3">
                        <h3 className="title text-dark">

                          {product.productName}
                        </h3>
                        <div className='d-flex justify-content-between my-2'>
                          <span className="text-success fw-bold" style={{ fontSize: "13px" }}>IN STOCK</span>
                          <span style={{ alignSelf: "flex-end", marginLeft: "auto", fontSize: "13px" }} ><strong style={{ color: "gray" }}>SKU</strong> <span className='ms-1 ' style={{ color: "gray" }}>194252786277</span></span>
                        </div>



                        <p><Link className='mt-5' style={{ color: "orange", fontSize: "12px" }}><b>BE THE FIRST TO REVIEW THIS PRODUCT</b></Link></p>


                        <div className='col-lg-12 card-description first-and-second-div p-0' style={{ backgroundColor: 'white' }}>
                          <div className='old-new-price  m-0  d-flex'>
                            <h3 className='new-price-cart  ' style={{ color: "#1D1D1F" }}><b style={{ fontSize: "50px" }}>₹{product.orgPrice}</b></h3> <span className="fs-4 ms-2 mt-3" style={{ color: "gray" }}><del style={{ fontSize: "20px" }}>₹{product.discountedPrice}</del></span>
                          </div>


                          <div className="col-md-4 col-6 mb-3">
                            <label className="mb-2 d-block">QAT</label>
                            <div className="input-group mb-3" style={{ width: '170px' }}>
                              <button onClick={decrement} className="btn btn-white px-3 " type="button" id="button-addon1" data-mdb-ripple-color="dark">
                                <i className="fas fa-minus" style={{ color: "gray" }}></i>
                              </button>
                              <strong className='mx-4 mt-2'>{count}</strong>
                              <button onClick={increment} className="btn btn-white px-3" type="button" id="button-addon2" data-mdb-ripple-color="dark">
                                <i className="fas fa-plus" style={{ color: "gray" }}></i>
                              </button>

                            </div>

                          </div>
                          <div className='buy-btns'>
                            <div className="d-grid gap-2">
                              <button className="btn btn-primary buy-btns p-2 fs-5" type="button" style={{ backgroundColor: '#1D1D1F', color: 'white' }} onClick={() => productAddedToCart(product, count)}>
                                <i className="fa-solid fa-bag-shopping  " style={{ transition: 'transform 0.3s' }}></i> &nbsp; &nbsp; ADD TO CART
                              </button>
                            </div>
                          </div>


                          <div id="compare-popup" style={{ display: 'none' }}>
                            <div className="compare-popup" style={{
                              border: '2px solid black',
                              borderRadius: '8px',
                              padding: '10px',
                              backgroundColor: 'white',
                              color: 'green',
                              display: 'flex',
                              alignItems: 'center',
                              marginTop: "5px"
                            }}>
                              <FontAwesomeIcon icon={faCheckCircle} style={{ marginRight: '8px' }} />
                              <span>Product added to compare!</span>
                            </div>
                          </div>

                          <div className='bottom-of-buy-btn-productDetail mt-4 mb-1 row'>
                            <div className='col-6 arrow-and-hurt ' style={{ cursor: 'pointer' }} onClick={() => { addToWishlist(product) }}>
                              <i className="fa-solid fa-heart"></i><span > ADD TO WISHLIST </span>
                            </div>
                            <div className='col-6 arrow-and-hurt text-end' style={{ cursor: 'pointer' }} onClick={() => handleAddToCompare(product)}>
                              <i className="fa-solid fa-arrow-right-arrow-left"></i><span> ADD TO COMPARE</span>
                            </div>

                          </div>

                          <div className='social-media-icon-cart-page mt-4'>
                            <i className="fa-brands fa-facebook-f"></i>
                            <i className="fa-brands fa-twitter"></i>
                            <i className="fa-brands fa-linkedin-in"></i>
                            <i className="fa-brands fa-pinterest"></i>
                            <i className="fa-brands fa-square-whatsapp"></i>
                          </div>

                        </div>

                        <div className='col-lg-12 first-and-second-div' >
                          <div>
                            <p>Check For Cash On Delivery</p>
                            <input type='text' className='w-75 mb-3 rounded-1' style={{ border: "1px solid gray ", height: "35px", color: "gray" }} required placeholder='Enter Pincode To Check COD' onChange={(e) => setPincodes(e.target.value)} /><button type="button" className="btn rounded-5 fw-bold  mb-2 " style={{ color: "#1D1D1F", border: "1px solid #1D1D1F ", fontSize: "10px", width: "15%", padding: "10px" }} onClick={checkCOD}><span className='co'>CHECK</span></button>
                            {error && <p className="text-danger">{error}</p>}
                            {codAvailable === true && <p>Cash on Delivery is available for this pin code.</p>}
                            {codAvailable === false && <p>Cash on Delivery is not available for this pin code.</p>}<br />

                            <button type="button" className="btn btn-outline-warning mt-3 rounded-5 border-0" style={{ backgroundColor: '#1D1D1F', color: 'white' }}>CASHBACK & EMI</button>
                          </div>
                          <div className="product attribute overview mt-4 mb-4" style={{ lineHeight: '1' }}>
                            <strong className="type">Quick Overview</strong>
                            <div className="value mt-3" itemprop="description" style={{ lineHeight: '1' }}>
                              <ul style={{ lineHeight: '1' }}>
                                <li style={{ lineHeight: '1' }}>128 GB ROM</li>
                                <li style={{ lineHeight: '1' }}>17.02 cm (6.7 inch) Super Retina XDR Display</li>
                                <li style={{ lineHeight: '1' }}>48MP + 12MP | 12MP Front Camera</li>
                                <li style={{ lineHeight: '1' }}>A16 Bionic Chip, 6 Core Processor Processor</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetailPopup;
