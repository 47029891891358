import React from 'react'
import Header1 from './Header1'
import Footer from './Footer'
import Form from 'react-bootstrap/Form';

function AdvanceSearch() {
    return (
        <>
            <Header1 />
            <div className="row justify-content-center" style={{ backgroundColor: "#F5F3EA", padding: "25px" }}>
                <h4 className="text-center mb-4">ADVANCED SEARCH</h4>
                <div className="p-3 col-lg-6 col-md-6 col-sm-12" style={{ backgroundColor: "white", Width: "50%" }}>
                    <p className="text-center fw-bold">SEARCH SETTINGS</p>
                    <hr />
                    <form >
                        <label>Product Name</label><br />
                        <input className='form-control w-100 ' style={{ border: "1px solid lightGray" }} /><br />
                        <label>Description</label><br />
                        <input className='form-control w-100 ' style={{ border: "1px solid lightGray" }} /><br />
                        <label>Short Description</label><br />
                        <input className='form-control w-100 ' style={{ border: "1px solid lightGray" }} /><br />
                        <label>Price</label><br />
                        <span className='d-flex '><input className='form-control w-50 me-2' /> -  <input className='form-control w-50 mx-2' /> INR</span> <br />
                        <label className='mt-3'>Manufacturer</label><br />
                        <Form.Select aria-label="Default select example" className='mb-3 ' >
                            <option value="1">Amazfit</option>
                            <option value="2">Amazon</option>
                            <option value="3">Asus</option>
                            <option value="3">Beetel</option>
                            <option value="3">Boat</option>
                            <option value="3">Boost12</option>
                            <option value="3">Casio</option>
                            <option value="3">Dell</option>
                            <option value="3">HP</option>
                            <option value="3">I-ball</option>
                            <option value="3">Lenovo</option>
                            <option value="3">LG</option>
                            <option value="3">Samsung</option>
                        </Form.Select>
                        <label>Color</label><br />
                        <Form.Select aria-label="Default select example" className='mb-3 ' >
                            <option value="1">Alpha steel</option>
                            <option value="2">Arctic sky</option>
                            <option value="3">Arctic steel</option>
                            <option value="3">Armoured edition</option>
                            <option value="3">Black</option>
                            <option value="3">Blue</option>
                            <option value="3">Brown</option>
                            <option value="3">Charcoal</option>
                            <option value="3">Crominiun steel</option>
                            <option value="3">Red</option>
                            <option value="3">Gold</option>
                            <option value="3">Rose Pink</option>
                            <option value="3">Yellow</option>
                        </Form.Select>
                        <label>Size</label><br />
                        <Form.Select aria-label="Default select example" className='mb-3 ' >
                            <option value="1">0</option>
                            <option value="2">11</option>
                            <option value="3">7</option>
                            <option value="3">9</option>
                            <option value="3">XS</option>
                            <option value="3">S</option>
                            <option value="3">M</option>
                            <option value="3">L</option>
                            <option value="3">XL</option>
                            <option value="3">6</option>
                            <option value="3">12</option>
                            <option value="3">14</option>
                            <option value="3">40</option>
                        </Form.Select>
                        <label>3G Support</label><br />
                        <Form.Select aria-label="Default select example" className='mb-3'>
                            <option value="1">All</option>
                            <option value="2">Yes</option>
                            <option value="3">No</option>
                        </Form.Select>
                        <label>Bluetooth</label><br />
                        <Form.Select aria-label="Default select example" className='mb-3'>
                            <option value="1">All</option>
                            <option value="2">Yes</option>
                            <option value="3">No</option>
                        </Form.Select>
                        <label>Call Memory</label><br />
                        <Form.Select aria-label="Default select example" className='mb-3'>
                            <option value="1">All</option>
                            <option value="2">Yes</option>
                            <option value="3">No</option>
                        </Form.Select>
                        <label>Card Class</label><br />
                        <Form.Select aria-label="Default select example" className='mb-3'>
                            <option value="1">Class 4 Memory Card</option>
                            <option value="2">Class 6 Memory Card</option>
                            <option value="3">Class 10 Memory Card</option>
                        </Form.Select>
                        <label>Charge Time</label><br />
                        <input className='form-control w-100 ' style={{ border: "1px solid lightGray" }} /><br />
                        <label>Content Formats Supported</label><br />
                        <input className='form-control w-100 ' style={{ border: "1px solid lightGray" }} /><br />
                        <label>Expandable Memory</label><br />
                        <Form.Select aria-label="Default select example" className='mb-3'>
                            <option value="1">32 mb</option>
                            <option value="2">NO</option>
                            <option value="3">up to 2 GB</option>
                            <option value="3">up to 4 GB</option>
                            <option value="3">up to 8 GB</option>
                            <option value="3">up to 16 GB</option>
                            <option value="3">up to 32 GB</option>
                            <option value="3">up to 64 GB</option>
                        </Form.Select>
                        <label>Headphone Jack</label><br />
                        <input className='form-control w-100 ' style={{ border: "1px solid lightGray" }} /><br />
                        <label>Included in the Box</label><br />
                        <input className='form-control w-100 ' style={{ border: "1px solid lightGray" }} /><br />
                        <label>Maximum Power Input</label><br />
                        <input className='form-control w-100 ' style={{ border: "1px solid lightGray" }} /><br />
                        <label>Speed</label><br />
                        <input className='form-control w-100 ' style={{ border: "1px solid lightGray" }} /><br />
                        <label>Touch Screen</label><br />
                        <Form.Select aria-label="Default select example" className='mb-3'>
                            <option value="1">All</option>
                            <option value="2">Yes</option>
                            <option value="3">No</option>
                        </Form.Select>
                        <label>Wi-Fi</label><br />
                        <Form.Select aria-label="Default select example" className='mb-3'>
                            <option value="1">All</option>
                            <option value="2">Yes</option>
                            <option value="3">No</option>
                        </Form.Select>
                        <label>Music Player</label><br />
                        <Form.Select aria-label="Default select example" className='mb-3'>
                            <option value="1">All</option>
                            <option value="2">Yes</option>
                            <option value="3">No</option>
                        </Form.Select>
                        <label>Phone Book Memory</label><br />
                        <Form.Select aria-label="Default select example" className='mb-3'>
                            <option value="1">100</option>
                            <option value="1">200</option>
                            <option value="1">300</option>
                            <option value="1">400</option>
                            <option value="1">500</option>
                            <option value="1">600</option>
                            <option value="1">700</option>
                            <option value="1">800</option>
                            <option value="1">900</option>
                            <option value="1">1000</option>
                            <option value="1">1200</option>
                            <option value="1">1500</option>
                            <option value="1">2000</option>
                            <option value="1">2500</option>
                            <option value="2">Yes</option>
                            <option value="3">No</option>
                        </Form.Select>
                        <label>SMS Memory</label><br />
                        <Form.Select aria-label="Default select example" className='mb-3'>
                            <option value="1">All</option>
                            <option value="2">Yes</option>
                            <option value="3">No</option>
                        </Form.Select>
                        <label>Torch</label><br />
                        <Form.Select aria-label="Default select example" className='mb-3'>
                            <option value="1">All</option>
                            <option value="2">Yes</option>
                            <option value="3">No</option>
                        </Form.Select>
                        <label>Other Performance Features</label><br />
                        <input className='form-control w-100 ' style={{ border: "1px solid lightGray" }} /><br />
                        <label>Memory size</label><br />
                        <Form.Select aria-label="Default select example" className='mb-3'>
                            <option value="1">128 GB</option>
                            <option value="2">16 GB</option>
                            <option value="3"> 2 GB</option>
                            <option value="3">4 GB</option>
                            <option value="3"> 8 GB</option>
                            <option value="3"> 32 GB</option>
                            <option value="3"> 64 GB</option>
                        </Form.Select>
                        <label>USP</label><br />
                        <input className='form-control w-100 ' style={{ border: "1px solid lightGray" }} /><br />

                        <label>Color</label><br />
                        <input className='form-control w-100 ' style={{ border: "1px solid lightGray" }} /><br />

                        <label>Battery Capacity</label><br />
                        <input className='form-control w-100 ' style={{ border: "1px solid lightGray" }} /><br />

                        <label>Memory Card Slot</label><br />
                        <Form.Select aria-label="Default select example" className='mb-3'>
                            <option value="1">All</option>
                            <option value="2">Yes</option>
                            <option value="3">No</option>

                        </Form.Select>

                        <label>Speaker Type</label><br />
                        <input className='form-control w-100 ' style={{ border: "1px solid lightGray" }} /><br />

                        <label>Screen Type</label><br />
                        <input className='form-control w-100 ' style={{ border: "1px solid lightGray" }} /><br />

                        <label>HD Technology & Resolution</label><br />
                        <input className='form-control w-100 ' style={{ border: "1px solid lightGray" }} /><br />

                        <label>Smart TV</label><br />
                        <Form.Select aria-label="Default select example" className='mb-3'>
                            <option value="1">All</option>
                            <option value="2">Yes</option>
                            <option value="3">No</option>

                        </Form.Select>

                        <label>HDMI</label><br />
                        <input className='form-control w-100 ' style={{ border: "1px solid lightGray" }} /><br />

                        <label>USB</label><br />
                        <input className='form-control w-100 ' style={{ border: "1px solid lightGray" }} /><br />

                        <label>Ethernet (RJ45)</label><br />
                        <Form.Select aria-label="Default select example" className='mb-3'>
                            <option value="1">All</option>
                            <option value="2">Yes</option>
                            <option value="3">No</option>

                        </Form.Select>

                        <label>Operating System</label><br />
                        <input className='form-control w-100 ' style={{ border: "1px solid lightGray" }} /><br />

                        <label>Sound Technology</label><br />
                        <input className='form-control w-100 ' style={{ border: "1px solid lightGray" }} /><br />

                        <label>Variant</label><br />
                        <Form.Select aria-label="Default select example" className='mb-3'>
                            <option value="1">6 GB + 128 GB</option>
                            <option value="2">8 GB + 128 GB</option>
                            <option value="3">12 GB +256 GB</option>
                            <option value="3">4 GB +64 GB</option>
                            <option value="3">8 GB +256 GB</option>
                            <option value="3">2 GB +32GB</option>
                            <option value="3">2 GB +16 GB</option>
                            <option value="3">5 GB + 521GB</option>
                            <option value="3">64 GB</option>
                            <option value="3"> 521GB</option>
                            <option value="3">32 GB</option>
                            <option value="3">128 GB</option>
                            <option value="3">12GB + 521GB</option>
                            <option value="3">1 TB</option>
                            <option value="3">12 GB + 1 TB</option>
                            <option value="3">16 GB + 256GB</option>
                            <option value="3">2 TB</option>
                            <option value="3">2 GB + 64GB</option>
                            <option value="3">8 GB + 1 TB</option>

                        </Form.Select>

                        <label>Display-Size</label><br />
                        <Form.Select aria-label="Default select example" className='mb-3'>
                            <option value="1">126 cm (5 inch)</option>
                            <option value="2">138 cm (55 inch)</option>
                            <option value="3">164 cm (65 inch)</option>
                            <option value="3">80 cm (32 inch)</option>
                            <option value="3">108 cm (43 inch)</option>
                            <option value="3">189.34 cm (75 inch)</option>
                            <option value="3">100 cm (40 inch)</option>
                            <option value="3">38.8 cm (55 inch)</option>

                        </Form.Select>

                        <button className='rounded-5 mt-3 px-3 text-white' style={{ backgroundColor: "#1D1D1F" }}>Search</button>
                    </form>

                </div>
            </div>
            <Footer />
        </>
    )
}

export default AdvanceSearch;