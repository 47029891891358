import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faWhatsapp, faYoutube } from '@fortawesome/free-brands-svg-icons';

const SocialMediaIcons = () => {
  const iconStyle = {
    display: 'block',
    width: '55px',
    height: '55px',
    lineHeight: '55px',
    textAlign: 'center',
    backgroundColor: '#333',
    color: '#fff',
    //   borderRadius: '20%',
    marginBottom: '10px',
    transition: 'all 0.3s ease'
  };

  const stickyIconsStyle = {
    position: 'fixed',
    top: '50%',
    right: '20px',
    transform: 'translateY(-50%)',
    zIndex: '999',
  };

  return (
    <div className="App">
      <div className='mobileDeviceHide' style={stickyIconsStyle}>
        <a href="https://www.facebook.com" style={{ ...iconStyle, backgroundColor: '#396fa8' }} target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faFacebook} style={{ height: '40px', marginTop: '10px' }} />
        </a>
        <a href="https://faWhatsapp.com" style={{ ...iconStyle, backgroundColor: '#00b528' }} target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faWhatsapp} style={{ height: '40px', marginTop: '10px' }} />
        </a>
        <a href="https://www.faYoutube.com" style={{ ...iconStyle, backgroundColor: '#e30000' }} target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faYoutube} style={{ height: '40px', marginTop: '10px' }} />
        </a>
      </div>
    </div>
  )
}

export default SocialMediaIcons;