import React from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Header1 from './Header1';
import Footer from './Footer';
import SocialMediaIcons from './SocialMediaIcons';

function ContactUs() {
    return (
        <>
            <Header1 />
            <SocialMediaIcons />
            <div style={{ backgroundColor: "#F5F3EA" }}>
                <h2 className='text-center'>CONTACT US</h2>
                <div className='container p-lg-5'>
                    <div className='row'>
                        <div className='col-lg-6 col-md-12 col-sm-12 mb-4'>
                            <Form className='border p-3 bg-white'>
                                <div className='mb-4'>
                                    <h5>WRITE US</h5>
                                    <span style={{ fontSize: "12px" }}>Jot us a note and we’ll get back to you as quickly as possible.</span>
                                </div>
                                <Row className="mb-3">
                                    <Form.Group as={Col} controlId="formGridName">
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control type="text" required />
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="formGridEmail">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control type="email" required />
                                    </Form.Group>
                                </Row>
                                <Form.Group className="mb-3" controlId="formGridPhoneNumber">
                                    <Form.Label>Phone Number</Form.Label>
                                    <Form.Control type='tel' maxLength={10} required />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formGridText">
                                    <Form.Label>What’s on your mind?</Form.Label>
                                    <Form.Control as="textarea" maxLength={100} required />
                                </Form.Group>
                                <Button variant="primary" type="submit">
                                    Submit
                                </Button>
                            </Form>
                        </div>
                        <div className='col-lg-6 col-md-12'>
                            <div style={{ maxWidth: '100%', overflowX: 'auto' }}>
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d59066.13300555447!2d70.789494!3d22.291876!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3959ca2471312a07%3A0xabc998708aa8d138!2sBAPS%20Shri%20Swaminarayan%20Mandir%2C%20Rajkot!5e0!3m2!1sen!2sus!4v1707314051561!5m2!1sen!2sus"
                                    width="100%"
                                    height="584"
                                    allowFullScreen=""
                                    loading="lazy"
                                    style={{ border: 0 }}
                                ></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default ContactUs;