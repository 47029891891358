import React, { useEffect, useState } from 'react'
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Link, useParams } from 'react-router-dom';
import Accordion from 'react-bootstrap/Accordion';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';
import Footer from './Footer';
import Products from './Products';
import Header1 from './Header1';

function SubProductPage() {

    const [products, setProducts] = useState([]);
    const [moreInformation, setMoreInformation] = useState([]);
    const { categoryName, subcategory } = useParams();
    const [open, setOpen] = useState(true);
    const [selectedFilters, setSelectedFilters] = useState({});
    const [priceRange, setPriceRange] = useState({ min: 0, max: Infinity });


    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const encodedsubcategory = encodeURIComponent(subcategory);
                const response = await fetch(`https://jinendra.online/api/products_allProducts?subcategory=${encodedsubcategory}`);
                if (!response.ok) {
                    throw new Error('Failed to fetch products');
                }
                const data = await response.json();
                setProducts(data.dt1);
                setMoreInformation(data.dt2);
            } catch (error) {
                console.error('Error fetching products:', error);
            }
        };

        fetchProducts();
    }, [subcategory]);

    const filterByPriceRange = (product) => {
        const { discountedPrice } = product;
        return discountedPrice >= priceRange.min && discountedPrice <= priceRange.max;
    };

    const handleSelect = (filterType, value) => {
        if (filterType === 'priceRange') {
            setPriceRange(value);
        } else {
            setSelectedFilters((prevFilters) => ({
                ...prevFilters,
                [filterType]: value,
            }));
        }
    };

    const removeFilter = (filterType) => {
        setSelectedFilters((prevFilters) => {
            const updatedFilters = { ...prevFilters };
            delete updatedFilters[filterType];
            return updatedFilters;
        });
    };

    const combinedData = products.map((product, index) => ({
        ...product,
        ...moreInformation[index],
        key: product.productId
    }));

    const filteredProducts = combinedData.filter((item) => {
        for (const filterType in selectedFilters) {
            if (filterType === 'priceRange') continue;
            if (item[filterType] !== selectedFilters[filterType]) {
                return false;
            }
        }
        return filterByPriceRange(item);
    });

    const [selectedItem, setSelectedItem] = useState("Select an item");

    // Function to handle item selection
    const handleItemClick = (item) => {
        setSelectedItem(item);
    };

    const settings2 = {
        className: "center",
        centerMode: true,
        infinite: false,
        centerPadding: "5px",
        slidesToShow: 1,
        speed: 500,
        rows: 3,
        initialSlide: 0,
        slidesPerRow: 2,
        prevArrow: <></>, // Remove the previous arrow
        nextArrow: <></> // Remove the next arrow
    };

    const [activeKeys, setActiveKeys] = useState(["1", "3", "4", "5", "6", "9"]);
    const handleAccordionToggle = (key) => {
        if (activeKeys.includes(key)) {
            setActiveKeys(activeKeys.filter(item => item !== key));
        } else {
            setActiveKeys([...activeKeys, key]);
        }
    };

    return (
        <>
            <Header1 />
            <div style={{ backgroundColor: '#F5F3EA', }}>
                <div className='container' >
                    <Breadcrumb className='pt-3'>
                        <Breadcrumb.Item style={{ textDecoration: "none" }}><Link to="/" style={{ color: "gray" }} >Home</Link></Breadcrumb.Item>
                        <Breadcrumb.Item active>{categoryName}</Breadcrumb.Item>
                        <Breadcrumb.Item active>{subcategory}</Breadcrumb.Item>
                    </Breadcrumb>

                    <h3 className='fw-bold'>{subcategory}</h3>
                    <div className='row '>

                        {/* side nave bar for large screen */}
                        <div className='col-lg-3 col-md-12 d-none d-lg-block'>
                            <div className='bg-white '>
                                <p className='m-0' style={{ backgroundColor: "black", padding: "10px", color: "white" }}><b >SHOP BY</b></p>
                                <p className=' pt-3 px-3 m-0'>SHOPING OPTION</p><hr></hr>
                                <p>Filtered:</p>
                                {Object.entries(selectedFilters).map(([filterType, filterValue]) => (
                                    <div key={filterType}>
                                        <span>{`${filterType}: ${filterValue}`}</span>
                                        <Button variant="light" onClick={() => removeFilter(filterType)}>X</Button>
                                    </div>
                                ))}
                                <Accordion className='px-3' activeKey={activeKeys}>
                                    <Accordion.Item style={{ border: "none" }} eventKey="1">
                                        <Accordion.Header onClick={() => handleAccordionToggle("1")}><b>PRICE</b></Accordion.Header>
                                        <Accordion.Body>
                                            <ul className="list-unstyled">
                                                <li onClick={() => handleSelect('priceRange', { min: 0, max: 5000 })}>Under ₹5000</li>
                                                <li onClick={() => handleSelect('priceRange', { min: 5000, max: 10000 })}>₹5000 - ₹10000</li>
                                                <li onClick={() => handleSelect('priceRange', { min: 10000, max: 20000 })}>₹10000 - ₹20000</li>
                                                <li onClick={() => handleSelect('priceRange', { min: 20000, max: 30000 })}>₹20000- ₹30000</li>
                                                <li onClick={() => handleSelect('priceRange', { min: 30000, max: Infinity })}>Above ₹30000</li>
                                            </ul>
                                        </Accordion.Body>
                                    </Accordion.Item>

                                    <Accordion.Item style={{ border: "none" }} eventKey="3">
                                        <Accordion.Header onClick={() => handleAccordionToggle("3")}><b>COLOR</b></Accordion.Header>
                                        <Accordion.Body>
                                            {console.log("Products data:", products)}
                                            {Array.from(new Set(moreInformation.map(item => item.color))).map(color => (
                                                <div
                                                    key={color}
                                                    style={{ cursor: 'pointer' }}
                                                    onMouseEnter={(e) => e.target.style.color = 'blue'}
                                                    onMouseLeave={(e) => e.target.style.color = 'black'}
                                                    onClick={() => handleSelect('color', color)}
                                                >
                                                    <p>{color}</p>
                                                </div>
                                            ))}
                                        </Accordion.Body>
                                    </Accordion.Item>

                                    <Accordion.Item style={{ border: "none" }} eventKey="4">
                                        <Accordion.Header onClick={() => handleAccordionToggle("4")}><b>BRAND</b></Accordion.Header>
                                        <Accordion.Body>
                                            {Array.from(new Set(moreInformation.map(item => item.Brand))).map(Brand => (
                                                <div
                                                    key={Brand}
                                                    style={{ cursor: 'pointer' }}
                                                    onMouseEnter={(e) => e.target.style.color = 'blue'}
                                                    onMouseLeave={(e) => e.target.style.color = 'black'}
                                                    onClick={() => handleSelect('Brand', Brand)}
                                                >
                                                    <p>{Brand}</p>
                                                </div>
                                            ))}
                                        </Accordion.Body>
                                    </Accordion.Item>

                                    {(categoryName === "MOBILE & TABLET" || categoryName === "LAPTOPS") && (
                                        <>
                                            <Accordion.Item style={{ border: "none" }} eventKey="5">
                                                <Accordion.Header onClick={() => handleAccordionToggle("5")}><b>RAM</b></Accordion.Header>
                                                <Accordion.Body>
                                                    {Array.from(new Set(moreInformation.map(item => item.RAM))).map(ram => (
                                                        <div
                                                            key={ram}
                                                            style={{ cursor: 'pointer' }}
                                                            onMouseEnter={(e) => e.target.style.color = 'blue'}
                                                            onMouseLeave={(e) => e.target.style.color = 'black'}
                                                            onClick={() => handleSelect('RAM', ram)}
                                                        >
                                                            <p>{ram}</p>
                                                        </div>
                                                    ))}
                                                </Accordion.Body>
                                            </Accordion.Item>

                                            <Accordion.Item style={{ border: "none" }} eventKey="6">
                                                <Accordion.Header onClick={() => handleAccordionToggle("6")}><b>INTERNAL MEMORY</b></Accordion.Header>
                                                <Accordion.Body>
                                                    {Array.from(new Set(moreInformation.map(item => item.Internal_Memory))).map(internalMemory => (
                                                        <div
                                                            key={internalMemory}
                                                            style={{ cursor: 'pointer' }}
                                                            onMouseEnter={(e) => e.target.style.color = 'blue'}
                                                            onMouseLeave={(e) => e.target.style.color = 'black'}
                                                            onClick={() => handleSelect('Internal_Memory', internalMemory)}
                                                        >
                                                            <p>{internalMemory}</p>
                                                        </div>
                                                    ))}
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </>
                                    )}

                                    <Accordion.Item style={{ border: "none" }} eventKey="9">
                                        <Accordion.Header onClick={() => handleAccordionToggle("9")}><b>RATING</b></Accordion.Header>
                                        <Accordion.Body>
                                            {Array.from(new Set(moreInformation.map(item => item.Rating))).map(rating => (
                                                <div
                                                    key={rating}
                                                    style={{ cursor: 'pointer' }}
                                                    onMouseEnter={(e) => e.target.style.color = 'blue'}
                                                    onMouseLeave={(e) => e.target.style.color = 'black'}
                                                    onClick={() => handleSelect('rating', rating)}
                                                >
                                                    <p>{rating}</p>
                                                </div>
                                            ))}
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                            <div className='bg-white mt-3'>
                                <p className='m-0' style={{ backgroundColor: "black", padding: "10px", color: "white" }}><b >FEATURED BRANDS</b></p>
                                <div className=" my-2 mx-4">
                                    <Slider {...settings2}>
                                        <div>
                                            <img src='/images/Brands/amazon.png' width={100} height={100} />
                                        </div>
                                        <div>
                                            <img src='/images/Brands/amazon.png' width={100} height={100} />
                                        </div>
                                        <div>
                                            <img src='/images/Brands/artis.png' width={100} height={100} />
                                        </div>
                                        <div>
                                            <img src='/images/Brands/beetel.png' width={100} height={100} />
                                        </div>
                                        <div>
                                            <img src='/images/Brands/boat.png' width={100} height={100} />
                                        </div>
                                        <div>
                                            <img src='/images/Brands/boost.png' width={100} height={100} />
                                        </div>
                                        <div>
                                            <img src='/images/Brands/casio.png' width={100} height={100} />
                                        </div>
                                        <div>
                                            <img src='/images/Brands/corseca.png' width={100} height={100} />
                                        </div>
                                        <div>
                                            <img src='/images/Brands/hp.png' width={100} height={100} />
                                        </div>
                                        <div>
                                            <img src='/images/Brands/iphone.png' width={100} height={100} />
                                        </div>
                                        <div>
                                            <img src='/images/Brands/itel.png' width={100} height={100} />
                                        </div>
                                        <div>
                                            <img src='/images/Brands/jbl.png' width={100} height={100} />
                                        </div>
                                        <div>
                                            <img src='/images/Brands/lenovo.png' width={100} height={100} />
                                        </div>
                                        <div>
                                            <img src='/images/Brands/leoxsys.png' width={100} height={100} />
                                        </div>
                                        <div>
                                            <img src='/images/Brands/mi.png' width={100} height={100} />
                                        </div>
                                        <div>
                                            <img src='/images/Brands/minigood.png' width={100} height={100} />
                                        </div>
                                    </Slider>
                                </div>
                            </div>
                        </div>

                        {/* shop by button for mobile devices and medium device */}
                        <div className='d-lg-none text-end '>
                            <Button
                                onClick={() => setOpen(!open)}
                                // aria-controls="example-collapse-text"
                                aria-expanded={open}
                                className='bg-white  rounded-5 mb-2 '
                                style={{ borderColor: " #3381f5" }}
                            >
                                <span style={{ color: "#3381f5" }}><i class="fa-solid fa-filter me-2" style={{ color: "#3381f5" }}></i>Sort By</span>
                            </Button>
                            <Collapse in={open}>
                                <div id="example-collapse-text">
                                    <div className='col-lg-3 '>
                                        <div className='bg-white '>
                                            <p className='m-0 text-start' style={{ backgroundColor: "black", padding: "10px", color: "white" }}><b >SHOP BY</b></p>
                                            <p className=' pt-3 px-3 m-0'>SHOPING OPTION</p><hr></hr>
                                            <Accordion className='px-3' alwaysOpen>
                                                <Accordion.Item style={{ border: "none" }} eventKey="0">
                                                    <Accordion.Header><b>CATOGERY</b></Accordion.Header>
                                                    <Accordion.Body>

                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item style={{ border: "none" }} eventKey="1">
                                                    <Accordion.Header><b>PRICE</b></Accordion.Header>
                                                    <Accordion.Body>

                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item style={{ border: "none" }} eventKey="3">
                                                    <Accordion.Header><b>COLOR</b></Accordion.Header>
                                                    <Accordion.Body>

                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item style={{ border: "none" }} eventKey="4">
                                                    <Accordion.Header><b>BRAND</b></Accordion.Header>
                                                    <Accordion.Body>

                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item style={{ border: "none" }} eventKey="5">
                                                    <Accordion.Header><b>RAM</b></Accordion.Header>
                                                    <Accordion.Body>

                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item style={{ border: "none" }} eventKey="6">
                                                    <Accordion.Header><b>INTERNAL MEMORY</b></Accordion.Header>
                                                    <Accordion.Body>

                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item style={{ border: "none" }} eventKey="7">
                                                    <Accordion.Header><b>RATING</b></Accordion.Header>
                                                    <Accordion.Body>

                                                    </Accordion.Body>
                                                </Accordion.Item>

                                            </Accordion>
                                        </div>
                                        <div className='bg-white mt-3'>
                                            <p className='m-0 text-start' style={{ backgroundColor: "black", padding: "10px", color: "white" }}><b >FEATURED BRANDS</b></p>
                                            <div className=" my-2 mx-4">
                                                <Slider {...settings2}>
                                                    <div>
                                                        <img src='../images/Brands/amazfit.png' width={100} height={100} type="png" />
                                                    </div>
                                                    <div>
                                                        <img src='../images/Brands/amazon.png' width={100} height={100} />
                                                    </div>
                                                    <div>
                                                        <img src='../images/Brands/artis.png' width={100} height={100} />
                                                    </div>
                                                    <div>
                                                        <img src='../images/Brands/beetel.png' width={100} height={100} />
                                                    </div>
                                                    <div>
                                                        <img src='../images/Brands/boat.png' width={100} height={100} />
                                                    </div>
                                                    <div>
                                                        <img src='../images/Brands/boost.png' width={100} height={100} />
                                                    </div>
                                                    <div>
                                                        <img src='../images/Brands/casio.png' width={100} height={100} />
                                                    </div>
                                                    <div>
                                                        <img src='../images/Brands/corseca.png' width={100} height={100} />
                                                    </div>
                                                    <div>
                                                        <img src='../images/Brands/hp.png' width={100} height={100} />
                                                    </div>
                                                    <div>
                                                        <img src='../images/Brands/iphone.png' width={100} height={100} />
                                                    </div>
                                                    <div>
                                                        <img src='../images/Brands/itel.png' width={100} height={100} />
                                                    </div>
                                                    <div>
                                                        <img src='../images/Brands/jbl.png' width={100} height={100} />
                                                    </div>
                                                    <div>
                                                        <img src='../images/Brands/lenovo.png' width={100} height={100} />
                                                    </div>
                                                    <div>
                                                        <img src='../images/Brands/leoxsys.png' width={100} height={100} />
                                                    </div>
                                                    <div>
                                                        <img src='../images/Brands/mi.png' width={100} height={100} />
                                                    </div>
                                                    <div>
                                                        <img src='../images/Brands/minigood.png' width={100} height={100} />
                                                    </div>
                                                </Slider>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Collapse>
                        </div>
                        <div className='col-lg-9 col-md-12 subcategory'>
                            {/* <Products products={products} /> */}
                            <Products products={filteredProducts} />
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default SubProductPage;
