import React from 'react';
import './Popup.css';

function Popup({ isVisible, onClose, firstName }) {

  return (
    <>
      {isVisible && (
        <div className="popup1">
          <div className="popup-content1">
            <span className='p-0'>Hey,{firstName}</span><br />
            <img src='../images/checkout.gif' width={200} height={180} />
            <h2 style={{ fontSize: "20px", marginBottom: "15px" }}>Your Order is Confirmed!</h2>
            {/* <p>We'll send you a shipping confirmation email as soon as your order ships.</p> */}
            <button className='border-0' style={{ backgroundColor: " #2ECC71", color: " #fff", padding: "10px 20px", borderRadius: "5px", cursor: "pointer", fontWeight: "bold" }} onClick={() => { onClose(); window.location.href = '/'; }}>OK</button>
          </div>
        </div>
      )}
    </>
  );
}

export default Popup;