import React, { useEffect, useState } from 'react';
import './CompareProducts.css';
import { productAddedToCart } from './ProductDetail';
import Header1 from './Header1';
import Footer from './Footer';

const CompareProduct = () => {
  const [comparedProducts, setComparedProducts] = useState([]);
  const count = 1;

  useEffect(() => {
    const storedProducts = localStorage.getItem('compare_products');
    if (storedProducts) {
      setComparedProducts(JSON.parse(storedProducts));
    }
  }, []);

  const removeProduct = (index) => {
    const updatedProducts = [...comparedProducts];
    updatedProducts.splice(index, 1);
    setComparedProducts(updatedProducts);
    localStorage.setItem('compare_products', JSON.stringify(updatedProducts));
  };

  return (
    <>
      <Header1 />
      <div className="compare-products" style={{ backgroundColor: "#F5F3EA" }}>
        <h1 className='py-2'>Compared Products</h1>
        <div className="table-responsive mx-3" >
          <table className="table">
            <thead>
              <tr>
                <th>Product</th>
                {comparedProducts.map((product, index) => (
                  <th key={index}>Product {index + 1}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Basic Info</td>
                {comparedProducts.map((product, index) => (
                  <td key={index} style={{ width: "25%" }}>
                    <div className="card mb-4" >
                      <button className="btn-remove" onClick={() => removeProduct(index)}>X</button>
                      <img src={`https://jinendra.online/${product.basicInfo?.prodimg1}`} className=" img-fluid" alt={product.basicInfo?.productName} />
                      <div className="card-body">
                        <h5 className="card-title">{product.basicInfo?.productName}</h5>
                        <p className="card-text">Price: {product.basicInfo?.discountedPrice}</p>
                        <button className="btn btn-primary" onClick={() => productAddedToCart(product.basicInfo, count)}>Add to Cart</button>
                      </div>
                    </div>
                  </td>
                ))}
              </tr>
              {Object.keys(comparedProducts[0]?.moreInfo || {}).map((key, index) => (
                key !== '_id' && key !== 'productId' && key !== 'productName' && (
                  <tr key={key}>
                    <td>{key}</td>
                    {comparedProducts.map((product, idx) => (
                      <td key={idx}>{product.moreInfo[key]}</td>
                    ))}
                  </tr>
                )
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CompareProduct;